import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../../services/data.service';
import {ActivatedRoute} from '@angular/router';
import {CategoryHttpService} from '../../../https/category-http.service';

@Component({
  selector: 'app-expo-list',
  templateUrl: './expo-list.component.html',
  styleUrls: ['./expo-list.component.scss']
})
export class ExpoListComponent implements OnInit {
  public searchHandler: any;

  public apiDataService = new DataService();
  public expolist: any = [];
  public categories: any = [];
  public type: any = '';

  constructor(private route: ActivatedRoute, private http: HttpClient, private categoryService: CategoryHttpService) {}

  public ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type') ? this.route.snapshot.paramMap.get('type') : '';
    this.searchHandler = this.searchBoundHandler.bind(this);
    this.categoryService.get().subscribe(categories => this.categories = categories);
    this.http.get(this.apiDataService.getAPIServerURL('/expo/list/' + this.type)).subscribe(
      data => {
        this.expolist = data;
      }
    );
  }

  public searchBoundHandler(param: any): any {
    this.http.get(this.apiDataService.getAPIServerURL('/expo/search/' + param.search + '/' + this.type)).subscribe(
      data => {
        this.expolist = data;
      }
    );
  }

}

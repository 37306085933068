import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DataService} from 'src/app/services/data.service';
import {EventInterface} from '../../../interfaces/event.interface';
import {EventHttpService} from '../../../https/event-http.service';

@Component({
  selector: 'app-events-show',
  templateUrl: './events-show.component.html',
  styleUrls: ['./events-show.component.scss']
})
export class EventsShowComponent implements OnInit {

  public apiDataService = new DataService();
  public event?: EventInterface;

  public lastevents: any = [];

  public statusRu: Object = {
    'active': 'Active',
    'now': 'Now',
    'planned': 'Planned',
    'expired': 'Expired',
    'canceled': 'Canceled',
  };

  constructor(private route: ActivatedRoute, private http: EventHttpService) {
  }

  public ngOnInit(): void {
    const params = this.route.snapshot.params;
    this.http.get(params.id).subscribe(data => this.event = data);

    this.http.last().subscribe(res => this.lastevents = res.data);
  }

  public getStatus(status: string) {
    return this.statusRu[status];
  }

}

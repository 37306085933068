import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../services/data.service';
import {TokenStorageService} from 'src/app/services/token-storage.service';
import {ExpoInterface} from '../../../interfaces/expo.interface';
import {ExpoHttpService} from '../../../https/expo-http.service';

@Component({
  selector: 'app-expo-show',
  templateUrl: './expo-show.component.html',
  styleUrls: ['./expo-show.component.scss']
})
export class ExpoShowComponent implements OnInit {
  public apiDataService = new DataService();
  public expo?: ExpoInterface;
  public code: string = '';
  public user: any = {
    id: null
  };

  constructor(private route: ActivatedRoute, private http: ExpoHttpService, private tokenStorage: TokenStorageService) {}

  public ngOnInit(): void {
    const params = this.route.snapshot.params;
    const url = this.apiDataService.getClientURL() + '/expo/embed/' + params.id;

    this.user = this.tokenStorage.getUser();
    this.code = '<iframe src="' + url + '" frameborder="0" allowfullscreen style="height: 920px; width: 100%;"></iframe>';
    this.http.get(params.id).subscribe(data => this.expo = data);
  }
}

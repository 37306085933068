<main class="max-w-7xl m-auto px-5 mb-10">
  <div>
    <div class="md:grid md:grid-cols-4 md:gap-6">
      <div class="md:col-span-2 lg:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900 mt-5">Компании</h3>
        <p class="mt-1 text-sm text-gray-600">В этом разделе вы можете посмотреть все ваши компании, создать новые и отредактировать существующие.</p>
        <div class="mt-10 flex">
          <a href="/profile"
             class="border border-teal-500 text-teal-500 block rounded-sm font-bold py-4 px-6 mr-2 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2 fill-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"/>
            </svg>
            <span class="ml-3">Вернуться назад</span>
          </a>
        </div>
        <div class="mt-10">
          <div class="w-full">
            <h3 class="font-bold text-gray-600 text-left">Список ваших компаний</h3>
            <div *ngFor="let company of companies" class="flex flex-col p-3 my-2 bg-gray-100 rounded-2xl">
              <div class="flex items-center justify-between">
                <a href="/company/{{ company.id }}">
                  <span class="inline-block truncate w-40 text-gray-600 uppercase pt-2">
                    <img src="{{ company.logo }}" alt="" class="rounded-full h-6 w-6 shadow-md inline-block mr-3"> {{ company.name }}</span>
                </a>
                <a href="/profile/company/edit/{{ company.id }}" class="text-green-400 float-right"
                   title="Редактировать">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                  </svg>
                </a>
                <button (click)="delete(company.id)" class="text-red-400 float-right focus:outline-none" title="Удалить">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-3">
        <form action="#" method="POST" [formGroup]="companyData" (ngSubmit)="onSubmit(companyData.value)" enctype="multipart/form-data">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <label for="name" class="block text-sm font-medium text-gray-700">Название компании
                  <span class="text-sm text-red-500">*</span>
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="name" id="name" formControlName="name" class="py-3 pl-5 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="Name">
                </div>
                <app-error *ngIf="errors.get('name')" [error]="errors.get('name')"></app-error>
              </div>
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">Email <span class="text-sm text-red-500">*</span></label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="email" name="email" id="email" formControlName="email" class="py-3 pl-5 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="Email">
                </div>
                <app-error *ngIf="errors.get('email')" [error]="errors.get('email')"></app-error>
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">Логотип компании <span class="text-sm text-red-500">*</span></label>
                <div *ngIf="company.logo" class="w-52">
                  <img src="{{company.logo}}" alt="{{ company.name }}" class="w-full">
                </div>
                <div class="mt-1 flex items-center">
                  <label for="file_upload_logo" class="mt-5 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span>Загрузить Логотип</span>
                    <input (change)="onLogoSelected($event)" id="file_upload_logo" name="logo" type="file" class="sr-only">
                  </label>
                </div>
                <app-error *ngIf="errors.get('logo')" [error]="errors.get('logo')"></app-error>
              </div>
              <div>
                <label class="block text-sm font-medium text-gray-700">Обложка компании</label>
                <div *ngIf="company.preview" class="w-52">
                  <img src="{{company.preview}}" alt="{{ company.name }}" class="w-full">
                </div>
                <div class="mt-1 flex items-center">
                  <label for="file_upload_previes" class="mt-5 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span>Загрузить Обложку</span>
                    <input (change)="onPreviewSelected($event)" id="file_upload_previes" name="preview" type="file" class="sr-only">
                  </label>
                </div>
                <app-error *ngIf="errors.get('preview')" [error]="errors.get('preview')"></app-error>
              </div>
              <div>
                <label for="website" class="block text-sm font-medium text-gray-700">Вебсайт</label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="website" id="website" formControlName="website" class="py-3 pl-5 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="Website">
                </div>
              </div>
              <div>
                <label for="address" class="block text-sm font-medium text-gray-700">Адрес</label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="address" id="address" formControlName="address" class="py-3 pl-5 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="Address">
                </div>
              </div>
              <div>
                <label for="phone" class="block text-sm font-medium text-gray-700">Номер телефона</label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="phone" id="phone" formControlName="phone" class="py-3 pl-5 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="Phone">
                </div>
              </div>
              <div>
                <label for="description" class="block text-sm font-medium text-gray-700">
                  Краткое описание <span class="text-sm text-red-500">*</span>
                </label>
                <div class="mt-1">
                  <textarea id="description" name="description" formControlName="description" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Description"></textarea>
                </div>
                <app-error *ngIf="errors.get('description')" [error]="errors.get('description')"></app-error>
                <p class="mt-2 text-sm text-gray-500">
                  Небольшой текст о вашей компании
                </p>
              </div>
              <div>
                <label for="content" class="block text-sm font-medium text-gray-700">
                  Подробное описание <span class="text-sm text-red-500">*</span>
                </label>
                <div class="mt-1">
                  <textarea id="content" name="content" formControlName="content" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Content"></textarea>
                </div>
                <app-error *ngIf="errors.get('content')" [error]="errors.get('content')"></app-error>
                <p class="mt-2 text-sm text-gray-500">
                  Текст с подробным описанием вашей компании
                </p>
              </div>

              <div *ngIf="success.message" class="flex items-center bg-green-500 border-l-4 border-green-700 py-2 px-3 shadow-md mb-2">
                <div class="text-white max-w-xs ">
                  {{ success.message }}
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>

import {Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataService} from '../../../services/data.service';
import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader.js';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ModalService} from 'src/app/services/modal/modal.service';
import {Errors} from 'src/app/classes/errors';

@Component({
  selector: 'app-product-show',
  templateUrl: './product-show.component.html',
  styleUrls: ['./product-show.component.scss']
})
export class ProductShowComponent implements OnInit, AfterViewInit {
  @ViewChild('rendererContainer') rendererContainer!: ElementRef;

  errors: Errors = new Errors();
  apiDataService = new DataService();
  place: any = {};
  isBuy: Boolean = false;

  product: any = {
    name: '',
    vendor: '',
    serial: '',
    price: 0,
    preview: '',
    spec: '',
    description: '',
  };

  clientData = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    lastname: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
    message: new UntypedFormControl('')
  });

  productName: any = '';
  products: any = [];

  el: any = null;

  width = 580;
  height = 580;

  renderer = new THREE.WebGLRenderer({antialias: true});

  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;

  controls: any;

  constructor(private route: ActivatedRoute, private http: HttpClient, private modalService: ModalService) {

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x444444);

    this.renderer.shadowMap.enabled = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.camera = new THREE.PerspectiveCamera(50, this.width / this.height, 0.1, 30000);

    this.camera.position.set(0, 0, 0);

    const directionalLight = new THREE.SpotLight(0xffffff, 1);
    directionalLight.position.set(1.616, 1.996, -0.008);
    this.scene.add(directionalLight);


    const directionalLight2 = new THREE.SpotLight(0xffffff, 1);
    directionalLight2.position.set(-2.934, 2.633, -0.695);
    this.scene.add(directionalLight2);

    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(13, 2, 1.368);
    this.scene.add(pointLight);


    this.scene.add(this.camera);

    this.render();
  }

  ngOnInit(): void {
    this.productName = this.route.snapshot.paramMap.get('product');
    let place = this.route.snapshot.paramMap.get('place');

    this.http.get(this.apiDataService.getAPIServerURL('/places/' + place)).subscribe(
      data => {
        this.place = data;
        if (this.place.model.url) {
          this.showPlace({
            model: this.place.model.url,
            position: {
              x: 0,
              y: 0,
              z: 0
            }
          });
        }
      }
    );

    this.http.get(this.apiDataService.getAPIServerURL('/places/' + place + '/product/' + this.productName)).subscribe(
      data => {
        console.log(data);
        this.product = data;
      }
    );
  }

  public ngAfterViewInit() {
    this.el = this.rendererContainer.nativeElement;
    this.width = this.el.width ? this.el.width : 580;
    this.height = this.el.height ? this.el.height : 580;

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.addEventListener('change', this.render.bind(this));
    this.controls.enableZoom = true;
    this.controls.minDistance = 10;
    this.controls.maxDistance = 70;

    this.controls.target.set(0, 0, 0);

    this.controls.update();

    this.render();
  }

  showPlace(place: any) {

    let self = this;
    let dracoLoader = new DRACOLoader();

    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');
    dracoLoader.setDecoderConfig({type: 'js'});

    let loader = new GLTFLoader();
    loader.setDRACOLoader(dracoLoader);
    loader.setCrossOrigin('');

    loader.loadAsync(place.model, function() {

    }).then((gltf) => {

      if (gltf.scene) {
        self.setProducts(gltf.scene.children);
      }

      const objModel = self.getProductByName(self.productName);

      const productModel = new THREE.Mesh(objModel.geometry, objModel.material);
      productModel.rotation.set(0, 0, 180);

      this.scaleCameraFov(productModel);
      self.scene.add(productModel);

      self.render();
    });
  }

  scaleCameraFov(object: any) {
    const boundingBox: any = new THREE.Box3();

    boundingBox.setFromObject(object);

    const size = boundingBox.getSize();
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = this.camera.fov * (Math.PI / 180);

    this.camera.position.y = Math.abs(maxDim / 4 * Math.tan(fov * 2));
  }

  setColor(color: any) {
    this.product.material.color = new THREE.Color(color);
    this.render();
  }

  setProducts(list: any) {
    list.map((item: any) => {
      if (item.children.length != 0) {
        this.setProducts(item.children);
      }
      if (item.name.indexOf('object_product') >= 0 || item.name.indexOf('object_estate') >= 0) {
        this.products.push(item);
      }
    });
  }

  getProductByName(name: string): any {

    let currentProduct = {};

    this.products.map((product: any) => {
      if (product.name == name) {
        currentProduct = product;
      }
    });


    return currentProduct;
  }

  buyProduct() {
    this.isBuy = !this.isBuy;
  }

  onSubmit(client: any) {

    let order = {
      email: client.email,
      client_lastname: client.lastname,
      message: client.message,
      client_name: client.name,
      phone: client.phone,
      product: this.product.name,
      product_name: this.product.userData.name,
      places_id: this.place.id,
      owner_id: this.place.user_id,
    };


    this.http.post(this.apiDataService.getAPIServerURL('/product/order'), order, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    })
      .subscribe(
        (result: any) => {

          if (result.status == 'success') {
            this.modalService.open('Заказ продукции', result.msg, result.status);
            this.isBuy = false;
          }

        },
        this.onError.bind(this)
      );
  }

  onError(response: any) {
    if (response.error) {
      this.errors.record(response.error.errors);
    }
  }

  animate() {
    this.render();
  }

  render() {
    this.renderer.render(this.scene, this.camera);
  }

}

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataService} from './data.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})

export class SubscribeService {

  private apiDataService = new DataService();

  constructor(private http: HttpClient) {
  }

  public createSubscribe(formData: any): Observable<any> {
    return this.http.post(this.apiDataService.getAPIServerURL('/subscribe/create'), formData, httpOptions);
  }
}

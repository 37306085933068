import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DataService} from 'src/app/services/data.service';
import {PlaceInterface} from '../../../interfaces/place.interface';
import {PlaceHttpService} from '../../../https/place-http.service';

@Component({
  selector: 'app-expo-places',
  templateUrl: './expo-places.component.html',
  styleUrls: ['./expo-places.component.scss']
})
export class ExpoPlacesComponent implements OnInit {

  public apiDataService = new DataService();

  public place?: PlaceInterface;

  constructor(private route: ActivatedRoute, private http: PlaceHttpService) {}

  public ngOnInit(): void {
    const params = this.route.snapshot.params;
    const id: string = params.id ? params.id : '';
    this.http.get(id).subscribe(data => this.place = data);
  }

}

<main class="">
  <section class="container mx-auto px-4 py-12 sm:py-16 md:py-20 xl:py-28">
    <div class="grid lg:grid-cols-2 gap-10 items-center">
      <div class="space-y-8 lg:space-y-12">
        <div class="space-y-6">
          <h1 class="text-4xl  font-semibold">{{ 'ExpoPlaces is' | translate }}</h1>
          <p class=" text-xl">{{ 'ExpoPlaces we provide' | translate }}</p>
        </div>
        <app-subscribe></app-subscribe>
      </div>
      <div class="h-96 rounded-md overflow-hidden bg-cover bg-center mt-3"
           style="background-image: url('/assets/images/865Gw221.jpeg')"></div>
    </div>
  </section>

  <section class="container mx-auto px-4">
    <div class="grid grid-cols-3 gap-8 lg:grid-cols-6">
      <div class="flex justify-center">
        <a href="https://www.alpametrology.com/" target="_blank">
          <img src="/assets/images/clients/alpa_icon.png" alt="alpa">
        </a>
      </div>
      <div class="flex justify-center">
        <a href="https://varius.com.ua/" target="_blank">
          <img src="/assets/images/clients/varius_icon.png" alt="varius">
        </a>
      </div>
      <div class="flex justify-center">
        <a href="https://varitec.com.ua/" target="_blank">
          <img src="/assets/images/clients/varitec_icon.png" alt="varitec">
        </a>
      </div>
      <div class="flex justify-center">
        <img src="/assets/images/clients/industry_icon.png" alt="industry">
      </div>
      <div class="flex justify-center">
        <a href="https://www.iscar.com/" target="_blank">
          <img src="/assets/images/clients/iscar_icon.png" alt="iscar">
        </a>
      </div>
      <div class="flex justify-center">
        <a href="https://www.taegutec.com/" target="_blank">
          <img src="/assets/images/clients/taegutec_icon.png" alt="taegutec">
        </a>
      </div>
    </div>
  </section>

  <section class="container mx-auto px-4 py-12 sm:py-16 md:py-20 xl:py-28 space-y-12 sm:space-y-16 xl:space-y-24">
    <!-- Start Points -->
    <div class="flex flex-col lg:flex-row justify-between items-center space-y-6 lg:space-y-0 lg:space-x-10">
      <div class="w-full lg:w-1/2">
        <img class="w-full rounded-md" src="/assets/images/865Gw221.jpeg" alt="">
      </div>
      <div class="w-full lg:w-1/2">
        <div class="space-y-5 sm:space-y-6">
          <h3 class="text-3xl md:text-4xl font-semibold underline">{{ 'Industrial' | translate }}</h3>
          <p class="w-full sm:w-4/5 text-lg">
            {{ 'Industrial_text' | translate }}
          </p>
          <a href="/expo/industrial"
             class="bg-black transition-colors duration-300 py-2.5 px-5 rounded-lg text-white font-semibold flex space-x-2.5 w-36">
            <span>{{ 'Read' | translate }}</span>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"/>
                    </svg>
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row-reverse justify-between space-y-6 lg:space-y-0 lg:space-x-10">
      <div class="w-full lg:w-1/2">
        <img class="w-full rounded-md" src="/assets/images/artgallery.jpg" alt="">
      </div>
      <div class="w-full lg:w-1/2">
        <div class="space-y-5 sm:space-y-6">
          <h3 class="text-3xl md:text-4xl font-semibold underline">{{ 'Art Galleries' | translate }}</h3>
          <p class="w-full sm:w-4/5 text-lg">{{ 'An art exhibition' | translate }}</p>
          <a href="/expo/art"
             class="bg-black transition-colors duration-300 py-2.5 px-5 rounded-lg text-white font-semibold flex space-x-2.5 w-36">
            <span>{{ 'Read' | translate }}</span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"/>
              </svg>
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row justify-between items-center space-y-6 lg:space-y-0 lg:space-x-10">
      <div class="w-full lg:w-1/2">
        <img class="w-full rounded-md" src="/assets/images/realestate.jpg" alt="">
      </div>
      <div class="w-full lg:w-1/2">
        <div class="space-y-5 sm:space-y-6">
          <h3 class="text-3xl md:text-4xl font-semibold underline">{{ 'Real Estate' | translate }}</h3>
          <p class="w-full sm:w-4/5 text-lg">{{ 'A platform for interactive viewing' | translate }}</p>
          <a href="/expo/realestate"
             class="bg-black transition-colors duration-300 py-2.5 px-5 rounded-lg text-white font-semibold flex space-x-2.5 w-36">
            <span>{{ 'Read' | translate }}</span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
              </svg>
            </span>
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-gray-100">
    <div class="container mx-auto px-4 py-12 sm:py-16 md:py-20 xl:py-28">
      <div class="space-y-4 text-center mb-10 lg:mb-16">
        <h2 class="text-4xl md:text-5xl font-semibold">{{ 'Our Advantages' | translate }}</h2>
        <p class="text-lg md:max-w-md mx-auto">{{ 'We provide solutions' | translate }}</p>
      </div>
      <div class="grid sm:grid-cols-2 xl:grid-cols-4 gap-6 lg:gap-10">
        <div class="bg-white transition-shadow duration-200 shadow hover:shadow-xl p-6 rounded-lg space-y-6">
          <div class="bg-black flex items-center justify-center w-12 h-12 rounded-full text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"/>
            </svg>
          </div>
          <div class="space-y-4">
            <h4 class="text-2xl md:text-3xl font-semibold">{{ 'Interactivity' | translate }}</h4>
            <p>{{ 'We use 3D' | translate }}</p>
          </div>
        </div>
        <div class="bg-white transition-shadow duration-200 shadow hover:shadow-xl p-6 rounded-lg space-y-6">
          <div class="bg-black flex items-center justify-center w-12 h-12 rounded-full text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"/>
            </svg>
          </div>
          <div class="space-y-4">
            <h4 class="text-2xl md:text-3xl font-semibold">{{ 'Technologies' | translate }}</h4>
            <p>{{ 'Thanks to modern innovative' | translate }}</p>
          </div>
        </div>
        <div class="bg-white transition-shadow duration-200 shadow hover:shadow-xl p-6 rounded-lg space-y-6">
          <div class="bg-black flex items-center justify-center w-12 h-12 rounded-full text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
            </svg>
          </div>
          <div class="space-y-4">
            <h4 class="text-2xl md:text-3xl font-semibold">{{ 'Integration' | translate }}</h4>
            <p>{{ 'You can easily' | translate }}</p>
          </div>
        </div>
        <div class="bg-white transition-shadow duration-200 shadow hover:shadow-xl p-6 rounded-lg space-y-6">
          <div class="bg-black flex items-center justify-center w-12 h-12 rounded-full text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"/>
            </svg>
          </div>
          <div class="space-y-4">
            <h4 class="text-2xl md:text-3xl font-semibold">{{ 'Resources' | translate }}</h4>
            <p>{{ 'We help organizers' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container mx-auto sm:px-4 my-10">
    <div class="bg-black px-4 py-12 sm:py-16 md:py-20 xl:py-28 sm:rounded-lg text-white">
      <div class="space-y-4 text-center mb-8 lg:mb-12">
        <h2 class="text-4xl md:text-5xl font-semibold">{{ 'Start for Free Now' | translate }}</h2>
        <p class="text-lg md:w-9/12 mx-auto">{{ 'The free period' | translate }}
          <a href="/contacts" class="underline"> {{ 'Contacts' | translate }}</a>
        </p>
      </div>
      <div class="flex flex-col sm:flex-row space-y-2.5 sm:space-y-0 justify-center"></div>
    </div>
  </section>
</main>

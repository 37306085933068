import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-events-item',
  templateUrl: './events-item.component.html',
  styleUrls: ['./events-item.component.scss']
})
export class EventsItemComponent {

  @Input() event: any = {};

  constructor() {
  }
}

<div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
  <div class="max-w-md w-full space-y-8 p-10 rounded-lg">
    <div>
      <div class="flex items-center justify-start">
        <app-logo [color]="'black'"></app-logo>
      </div>
    </div>

    <form class="space-y-6" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" action="#" method="POST">
      <input type="hidden" name="remember" value="true">
      <div class="rounded-md shadow-sm -space-y-px">
        <div>
          <label for="email-address" class="sr-only">{{ 'Email address' | translate }}</label>
          <input id="email-address" type="email" autocomplete="email" required name="email" [(ngModel)]="form.email" #email="ngModel" class="mb-3 h-12 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address">
          <app-error *ngIf="errors.get('email')" [error]="errors.get('email')"></app-error>
        </div>
        <div>
          <label for="password" class="sr-only">{{ 'Password' | translate }}</label>
          <input id="password" name="password" type="password" autocomplete="current-password" required [(ngModel)]="form.password" #password="ngModel" class="h-12 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password">
          <app-error *ngIf="errors.get('password')" [error]="errors.get('password')"></app-error>
        </div>
      </div>

      <app-error *ngIf="errors.get('auth')" [error]="errors.get('auth')"></app-error>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
          <label for="remember_me" class="ml-2 block text-sm text-gray-900">
            {{ 'Remember me' | translate }}
          </label>
        </div>

        <div class="text-sm">
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            {{ 'Forgot password' | translate }}?
          </a>
        </div>
      </div>

      <div>
        <button type="submit"
          class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd"/>
              </svg>
            </span>
          {{ 'Login' | translate }}
        </button>
        <a routerLink="/register"
           class="group relative w-full flex justify-center py-2 px-4 mt-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          {{ 'Register' | translate }}
        </a>
      </div>
    </form>
  </div>
</div>

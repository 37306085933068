import { Injectable } from '@angular/core';
import {BaseHttpService} from "./base-http.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {API_ENUM, API_URL} from "../enums/http.enum";

@Injectable({
  providedIn: 'root'
})
export class PlaceHttpService extends BaseHttpService {

  constructor(private http: HttpClient) {
    super();
  }

  public get(id: string = ''): Observable<any> {
    return this.httpSender(this.http.get(`${API_URL}/${API_ENUM.PLACE}/${id}`));
  }
}

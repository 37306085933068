import { Injectable } from '@angular/core';
import {BaseHttpService} from './base-http.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_ENUM, API_URL} from '../enums/http.enum';

@Injectable({
  providedIn: 'root'
})
export class ProfileHttpService extends BaseHttpService {

  constructor(private http: HttpClient) {
    super();
  }

  public createPlace(params: object = {}): Observable<any> {
    this.buildParams(params);
    const $request = this.http.post(`${API_URL}/${API_ENUM.PROFILE}/${API_ENUM.CREATE_PLACE}`, this.formData);
    return this.httpSender($request);
  }
}

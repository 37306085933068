import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent {

  @Input() public loadPercent: any = '40%';

  constructor() {
    this.loadPercent = '40%';
  }

}

<main class="max-w-7xl m-auto px-5 mb-10">
  <div>
    <div class="md:grid md:grid-cols-4 md:gap-6">
      <div class="md:col-span-2 lg:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900 mt-5">Выставки</h3>
        <p class="mt-1 text-sm text-gray-600">В этом разделе вы можете создать новую выставкуы.</p>
        <div class="mt-10 flex">
          <a href="/profile"
             class="border border-teal-500 text-teal-500 block rounded-sm font-bold py-4 px-6 mr-2 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2 fill-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"/>
            </svg>
            <span class="ml-3">Вернуться назад</span>
          </a>
        </div>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-3">
        <form action="#" method="POST" [formGroup]="expoData" (ngSubmit)="onSubmit(expoData.value)" enctype="multipart/form-data">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <label for="name" class="block text-sm font-medium text-gray-700">Название выставки
                  <span class="text-sm text-red-500">*</span>
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="name" id="name" formControlName="name" class="py-3 pl-5 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="Название">
                </div>
                <app-error *ngIf="errors.get('name')" [error]="errors.get('name')"></app-error>
              </div>
              <div>
                <label class="block text-sm font-medium text-gray-700">Обложка выставки</label>
                <div *ngIf="expo.preview" class="w-52">
                  <img src="{{expo.preview}}" alt="{{ expo.name }}" class="w-full">
                </div>
                <div class="mt-1 flex items-center">
                  <label for="file_upload_previes" class="mt-5 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span>Загрузить Обложку</span>
                    <input (change)="onPreviewSelected($event)" id="file_upload_previes" name="preview" type="file" class="sr-only">
                  </label>
                </div>
                <app-error *ngIf="errors.get('preview')" [error]="errors.get('preview')"></app-error>
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">3D Модель выставки</label>
                <div class="mt-1 flex items-center">
                  <label for="file_upload_model" class="mt-5 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span>Загрузить 3D модель</span>
                    <input (change)="onModelSelected($event)" id="file_upload_model" name="model" type="file" class="sr-only">
                  </label>
                </div>
                <app-error *ngIf="errors.get('model')" [error]="errors.get('model')"></app-error>
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">Категория</label>
                <div class="mt-1 flex items-center">
                  <select name="category_id" formControlName="category_id">
                    <option *ngFor="let category of categories" value="{{ category.id }}">{{ category.name }}</option>
                  </select>
                </div>
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">Тип выставки</label>
                <div class="mt-1 flex items-center">
                  <select name="type" formControlName="type">
                    <option value="art">Арт выставка</option>
                    <option value="industrial">Индустриальная выставка</option>
                    <option value="realestate">Выставка недвижимости</option>
                  </select>
                </div>
              </div>

              <div>
                <label for="description" class="block text-sm font-medium text-gray-700">
                  Подробное описание <span class="text-sm text-red-500">*</span>
                </label>
                <div class="mt-1">
                  <textarea id="description" name="description" formControlName="description" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Описание"></textarea>
                </div>
                <app-error *ngIf="errors.get('description')" [error]="errors.get('description')"></app-error>
                <p class="mt-2 text-sm text-gray-500">
                  Текст с подробным описанием вашей выставки
                </p>
              </div>

              <div *ngIf="success.message" class="flex items-center bg-green-500 border-l-4 border-green-700 py-2 px-3 shadow-md mb-2">
                <div class="text-white max-w-xs ">
                  {{ success.message }}
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>

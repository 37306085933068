import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-standard-preview',
  templateUrl: './standard-preview.component.html',
  styleUrls: ['./standard-preview.component.scss']
})
export class StandardPreviewComponent {

  @Input() public path = '';
  @Input() public name = '';
  @Input() public preview = '';

  constructor() { }

}

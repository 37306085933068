<div class="bg-cover bg-center h-auto text-white  object-fill" [style.background-image]="'url(' + image  + ')'">
  <div class="bg-gray-900 bg-opacity-70 h-full py-10 ">
    <div class="max-w-7xl m-auto">
      <div class="px-5">
        <p class="font-bold text-sm uppercase text-blue-300 text-left">{{ page }}</p>
        <p class="text-3xl font-bold mt-2 text-left">{{ text }}</p>
        <p class="text-2xl mb-10 leading-none mt-2 text-gray-300 text-left">{{ slogan }}</p>
        <div>
          <div class="flex justify-start my-8">
            <div class="bg-white rounded-lg">
              <div class="flex">
                <form [formGroup]="searchData" (ngSubmit)="onSubmit(searchData.value)" method="post">
                  <input formControlName="search" type="text" class="m-1 p-2 appearance-none text-gray-700 text-sm focus:outline-none w-64" placeholder="{{ 'Search' | translate }}">
                  <button class="m-1 p-2 text-sm bg-gray-800 text-gray-100 rounded-lg font-semibold uppercase lg:w-auto">
                    {{ 'Search' | translate }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

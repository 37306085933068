import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlaceSidebarService {

  private visible: any = new BehaviorSubject(false);

  constructor() {
  }

  public watch(): Observable<boolean> {
    return this.visible.asObservable();
  }

  public open() {
    this.visible.next(true);
  }

  public close() {
    this.visible.next(false);
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DataService} from './data.service';
import {Router} from '@angular/router';
import {TokenStorageService} from './token-storage.service';


const AUTH_API = new DataService().getAPIServerURL('/api');

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private token: TokenStorageService, private http: HttpClient, private router: Router) {
  }

  public login(email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + '/login', {
      email,
      password
    }, httpOptions);
  }

  public logout(): Observable<any> {
    this.token.removeToken();

    return this.http.post(AUTH_API + '/logout', {}, httpOptions);
  }

  public register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + '/register', {
      username,
      email,
      password
    }, httpOptions);
  }

  public redirectIfUnauthorized() {
    this.router.navigate(['/login']);
  }
}

<div class="w-full h-full">
  <div class="grid lg:grid-cols-2 h-full">
    <div class="sm:w-full">
      <app-login></app-login>
    </div>
    <div class="bg-purple-500 sm:w-full"
         style="background-image: url('https://images.unsplash.com/photo-1562141959-8961d3397d88?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2734&q=80'); background-size: cover;">

      <div class="mt-48 ml-10 absolute z-20">
        <div class="px-6 md:max-w-3xl md:mx-auto lg:mx-0 lg:max-w-none">
          <app-logo [color]="'white'"></app-logo>
          <h1 class="mt-3 text-3xl font-semibold font-display text-white sm:mt-6 sm:text-4xl xl:text-5xl">
            <span class="uppercase">ExpoPlaces</span> - место где можно разместить свою продукцию
            и показать ее всему миру
          </h1>
          <div class="my-6 sm:flex sm:mt-8 xl:mt-12">
            <a href="/expo"
               class="w-full sm:w-auto inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-semibold rounded-md text-gray-900 bg-white shadow-sm hover:text-gray-600 focus:outline-none focus:text-gray-600 transition ease-in-out duration-150 xl:text-lg xl:py-4">
              Посмотреть выставки
            </a>
            <a href="/price"
               class="mt-4 sm:ml-4 sm:mt-0 w-full sm:w-auto inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-semibold rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-700 focus:outline-none focus:bg-gray-700 transition ease-in-out duration-150 xl:text-lg xl:py-4">
              Цены на услуги
            </a>
          </div>
        </div>
      </div>
      <div class="w-3/6 h-full absolute bg-gray-800 bg-opacity-80 z-10"></div>
    </div>
  </div>
</div>

import {Component} from '@angular/core';
import {TokenStorageService} from '../../../services/token-storage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public isAuth = false;
  public user: any = {
    name: ''
  };

  constructor(private tokenStorage: TokenStorageService) {
    this.user = tokenStorage.getUser();
    if (this.tokenStorage.getToken()) {
      this.isAuth = true;
    }
  }
}

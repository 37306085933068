<main class="mx-auto w-full px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-10">
  <div class="sm:text-center lg:text-left">
    <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
      <span class="block xl:inline">Категории</span>
    </h1>
    <div class="mt-5">
      <div class="w-screen h-screen flex justify-start items-start"></div>
    </div>
  </div>
</main>

<main class="max-w-7xl m-auto px-5 mb-10">
  <div class="sm:text-center lg:text-left">
    <h1 class="my-5 text-left"><b>Expo</b>Places - Кабинет клиента</h1>
  </div>
  <div>
    <div class="grid md:grid-cols-4 md:gap-6">
      <div class="w-full md:col-span-3 lg:col-span-1">
        <app-navigation-sidebar [user]="user"></app-navigation-sidebar>
      </div>
      <div class="w-full mt-5 md:mt-0 md:col-span-3">
        <form action="#" method="POST" [formGroup]="profileForm" enctype="multipart/form-data">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <label for="name" class="block text-sm font-medium text-gray-700">Имя <span class="text-sm text-red-500">*</span></label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="name" id="name" formControlName="name" class="py-3 pl-5 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="Name">
                </div>
                <app-error *ngIf="errors.get('name')" [error]="errors.get('name')"></app-error>
              </div>
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">Email <span class="text-sm text-red-500">*</span></label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="email" name="email" id="email" formControlName="email" class="py-3 pl-5 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="Email">
                </div>
                <app-error *ngIf="errors.get('email')" [error]="errors.get('email')"></app-error>
              </div>
              <div>
                <label for="phone" class="block text-sm font-medium text-gray-700">Номер телефона</label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="phone" id="phone" formControlName="phone" class="py-3 pl-5 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="Phone">
                </div>
              </div>
              <div>
                <div class="mt-1 flex items-center">
                  <label for="file-upload" class="mt-5 relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                    <span>Загрузить обложку для профиля</span>
                    <input (change)="onFileSelected($event)" id="file-upload" name="avatar" type="file" class="sr-only">
                  </label>
                </div>
                <app-error *ngIf="errors.get('avatar')" [error]="errors.get('avatar')"></app-error>
              </div>
              <div *ngIf="success.message" class="flex items-center bg-green-500 border-l-4 border-green-700 py-2 px-3 shadow-md mb-2">
                <div class="text-white max-w-xs ">
                  {{ success.message }}
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button (click)="save()" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>

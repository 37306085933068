import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../services/data.service';

@Component({
	selector: 'app-page-category',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

	private apiDataService = new DataService();
	private categories: any = [];

	constructor(private http: HttpClient) { }

	public ngOnInit(): void {
		this.http.get(this.apiDataService.getAPIServerURL('/categories')).subscribe(
			data => {
				this.categories = data;
			}
		);
	}
}

<div class="relative rounded-lg block md:flex items-center bg-gray-100 shadow-xl mx-5 mb-5">
  <div class="relative w-full md:w-2/5 h-full overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg"
       style="min-height: 19rem;" *ngIf="company.preview">
    <img class="absolute inset-0 w-full h-full object-cover object-center" src="{{ company.preview }}" alt="">
    <div class="absolute inset-0 w-full h-full flex items-center justify-center fill-current text-white">

    </div>
  </div>
  <div class="w-full md:w-3/5 h-full flex items-center bg-gray-100 rounded-lg">
    <div class="p-10 md:pr-5 md:pl-10 md:py-10">
      <h3 class="text-left text-lg font-bold text-gray-600 mb-3">
        <a href="/company/{{ company.id }}" class="">
          <img class="float-left h-12" src="{{ company.logo }}" alt="">
          <span class="ml-4" style="line-height: 3rem;">{{ company.name }}</span>
        </a>
      </h3>
      <p class="text-left text-gray-600" [innerHTML]="company.description.substring(0, 70)"></p>
      <a class="flex items-baseline mt-3 text-indigo-600 hover:text-indigo-900 focus:text-indigo-900"
         href="/company/{{ company.id }}">
        <div class="flex">
          <span class="mr-3">{{ 'Detailed' | translate }}</span>
          <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
          </svg>
        </div>
      </a>
    </div>
  </div>
</div>

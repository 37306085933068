<main class="max-w-7xl m-auto px-5 mt-10">
  <div class="sm:text-center lg:text-left">
    <h1 class="my-5 text-left"><b>Expo</b>Places - Список заказов</h1>
  </div>
  <div>
    <div class="grid md:grid-cols-4 md:gap-6">
      <div class="w-full md:col-span-3 lg:col-span-1">
        <app-navigation-sidebar [user]="user"></app-navigation-sidebar>
      </div>

      <div class="w-full mt-5 md:mt-0 md:col-span-3">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                  <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Название товара
                  </th>
                  <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Клиент
                  </th>
                  <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Сообщение
                  </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr *ngFor="let order of orders">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">{{ order.id }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">{{ order.product_name }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ order.client_name }} {{ order.client_lastname }}
                        </div>
                        <div class="text-sm text-blue-500" *ngIf="order.phone">
                          {{ order.phone }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ order.email }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ order.message }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <p class="text-sm text-gray-600 mt-5" *ngIf="orders.length === 0">На данный момент у вас нет добавленных стендов</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './pages/auth/login/login.component';
import {RegisterComponent} from './pages/auth/register/register.component';
import {CompanySaveComponent} from './profile/company/company-save/company-save.component';
import {ExpoComponent} from './widgets/expo/expo.component';
import {CategoryComponent} from './pages/category/category.component';
import {CompanyListComponent} from './pages/company/company-list/company-list.component';
import {CompanyShowComponent} from './pages/company/company-show/company-show.component';
import {ContactComponent} from './pages/contact/contact.component';
import {ExpoListComponent} from './pages/expo/expo-list/expo-list.component';
import {ExpoShowComponent} from './pages/expo/expo-show/expo-show.component';
import {LayoutComponent} from './layouts/layout/layout.component';
import {PriceComponent} from './pages/prices/price/price.component';
import {ProductShowComponent} from './pages/product/product-show/product-show.component';
import {ProfileOrderListComponent} from './profile/orders/profile-order-list/profile-order-list.component';
import {ProfileComponent} from './profile/profile.component';
import {ProductEditComponent} from './profile/stand/product-edit/product-edit.component';
import {StandEditComponent} from './profile/stand/stand-edit/stand-edit.component';
import {StandListComponent} from './profile/stand/stand-list/stand-list.component';
import {ExpoViewComponent} from './pages/expo/expo-view/expo-view.component';
import {ExpoPlacesComponent} from './pages/expo/expo-places/expo-places.component';
import {HomeIndexComponent} from './pages/home/home-index.component';
import {ProfileExpoListComponent} from './profile/expo/profile-expo-list/profile-expo-list.component';
import {ProfileExpoSaveComponent} from './profile/expo/profile-expo-save/profile-expo-save.component';
import {EventsListComponent} from './pages/events/events-list/events-list.component';
import {EventsShowComponent} from './pages/events/events-show/events-show.component';

const routes: Routes = [
  {
    path: 'expo/embed/:id',
    component: ExpoComponent,
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'register', component: RegisterComponent
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: HomeIndexComponent,
      },
      {
        path: 'expo',
        component: ExpoListComponent,
      },
      {
        path: 'expo/:type',
        component: ExpoListComponent,
      },
      {
        path: 'expo/show/:id',
        component: ExpoShowComponent,
      },
      {
        path: 'expo/online/:id',
        component: ExpoViewComponent,
      },
      {
        path: 'expo/places/:id',
        component: ExpoPlacesComponent,
      },
      {
        path: 'expo/product/:product/:place',
        component: ProductShowComponent,
      },
      {
        path: 'events',
        component: EventsListComponent,
      },
      {
        path: 'events/:id',
        component: EventsShowComponent,
      },
      {
        path: 'contacts',
        component: ContactComponent,
      },
      {
        path: 'company',
        component: CompanyListComponent,
      },
      {
        path: 'company/:id',
        component: CompanyShowComponent,
      },
      {
        path: 'categories',
        component: CategoryComponent,
      },
      {
        path: 'price',
        component: PriceComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'profile/company/create',
        component: CompanySaveComponent
      },
      {
        path: 'profile/company/edit/:id',
        component: CompanySaveComponent
      },
      {
        path: 'profile/stand/list',
        component: StandListComponent
      },
      {
        path: 'profile/stand/:id',
        component: StandEditComponent
      },
      {
        path: 'profile/stand/:id/product/:product',
        component: ProductEditComponent
      },
      {
        path: 'profile/expo/list',
        component: ProfileExpoListComponent
      },
      {
        path: 'profile/expo/create',
        component: ProfileExpoSaveComponent
      },
      {
        path: 'profile/order/list',
        component: ProfileOrderListComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

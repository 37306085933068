import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private API_SERVER_URL = environment.api_server;

  constructor() {
  }

  public getAPIServerURL(url: string) {
    return this.API_SERVER_URL + url;
  }

  public getClientURL() {
    return window.location.origin;
  }

  public getPort() {
    return (window.location.port) ? ':' + window.location.port : '';
  }

  public getHost() {
    return window.location.protocol + window.location.hostname;
  }
}

<div class="max-w-7xl m-auto px-5">
  <main class="my-10" *ngIf="place">
    <div class="mb-4 md:mb-0 w-full mx-auto relative">
      <div class="px-4 lg:px-0">
        <h2 class="text-4xl font-semibold text-gray-800 leading-tight">
          {{ place.name }}
        </h2>
      </div>
    </div>

    <div class="flex flex-col lg:flex-row lg:space-x-12">
      <div class="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-3/4">
        <div *ngIf="place.preview" class="mb-10">
          <img src="{{ place.preview }}" alt="" srcset="" class="w-full">
        </div>
        <p class="pb-6" [innerHTML]="place.description"></p>
      </div>

      <div class="w-full lg:w-1/3 mt-5 max-w-screen-sm">
        <div class="flex p-8 bg-white shadow-md hover:shodow-lg rounded-2xl">
          <div *ngIf="place.expo">
            <h3 class="font-medium text-2xl leading-none text-gray-700 my-3">
              <a href="/expo/{{ place.expo.id }}">{{ place.expo.name }}</a>
            </h3>
            <div class="flex items-center justify-between my-3" *ngIf="place.company">
              <a href="/company/{{ place.company.id }}">
                <span class="inline-block truncate w-40 text-gray-600 uppercase pt-2"><img
                  src="{{ place.company.logo }}" alt=""
                  class="rounded-full h-6 w-6 shadow-md inline-block mr-3"> {{ place.company.name }}</span>
              </a>
            </div>
            <img *ngIf="place.expo.image" alt="" src="{{ place.expo.image }}" class="w-full object-cover lg:rounded my-3"/>
            <div class="mt-8 text-gray-700" *ngIf="place.expo.description"
                 [innerHTML]="place.expo.description.slice(0, 250)"></div>

            <div class="mt-10 flex" *ngIf="place.expo">
              <a href="/expo/online/{{ place.expo.id }}"
                 class="w-full bg-blue-500 px-5 py-3 text-sm shadow-sm hover:shadow-lg font-medium border-2 border-blue-600 text-white text-center rounded-2xl">
                {{ 'Visit Expo' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

<div class="min-h-screen flex justify-center items-center">
  <div class="mb-20">
    <div class="text-center font-semibold">
      <h1 class="text-5xl">
        <span class="text-gray-700 tracking-wide">{{ 'Pricing' | translate }}</span>
      </h1>
      <p class="pt-6 text-xl text-gray-400 font-normal w-full px-8 md:w-full">
        {{ 'Select plan' | translate }}
      </p>
    </div>
    <div class="pt-24 flex flex-row">
      <div class="w-96 p-8 bg-white text-center rounded-3xl pr-16 shadow-xl">
        <h1 class="text-black font-semibold text-2xl">{{ 'Basic' | translate }}</h1>
        <p class="pt-2 tracking-wide">
          <span class="text-gray-400 align-top">$ </span>
          <span class="text-3xl font-semibold">15</span>
          <span class="text-gray-400 font-medium">/ {{ 'month' | translate }}</span>
        </p>
        <hr class="mt-4 border-1">
        <div class="pt-8">
          <p class="font-semibold text-green-700 text-left">
            <span class="material-icons align-middle">
              done
            </span>
            <span class="pl-2">{{ 'One place' | translate }}</span>
          </p>
          <p class="font-semibold text-green-700 text-left pt-5">
            <span class="material-icons align-middle">
                done
            </span>
            <span class="pl-2">{{ 'One company' | translate }}</span>
          </p>
          <p class="font-semibold text-red-700 text-left pt-5">
            <span class="material-icons align-middle">
                close
            </span>
            <span class="pl-2">{{ 'Integration' | translate }}</span>
          </p>

          <a href="/contacts" class="">
            <p class="w-full py-4 bg-blue-600 mt-8 rounded-xl text-white">
              <span class="font-medium">
                  {{ 'Contact' | translate }}
              </span>
              <span class="pl-2 material-icons align-middle text-sm">
                  east
              </span>
            </p>
          </a>
        </div>
      </div>
      <!-- StartUp Card -->
      <div
        class="w-80 p-8 bg-gray-900 text-center rounded-3xl text-white border-4 shadow-xl border-white transform scale-125">
        <h1 class="text-white font-semibold text-2xl">{{ 'Optima' | translate }}</h1>
        <p class="pt-2 tracking-wide">
          <span class="text-gray-400 align-top">$ </span>
          <span class="text-3xl font-semibold">30</span>
          <span class="text-gray-400 font-medium">/ {{ 'month' | translate }}</span>
        </p>
        <hr class="mt-4 border-1 border-gray-600">
        <div class="pt-8">
          <p class="font-semibold text-green-700 text-left">
                        <span class="material-icons align-middle">
                            done
                        </span>
            <span class="pl-2">{{ 'Five place' | translate }}</span>
          </p>
          <p class="font-semibold text-green-700 text-left pt-5">
                        <span class="material-icons align-middle">
                            done
                        </span>
            <span class="pl-2">{{ 'Five company' | translate }}</span>
          </p>
          <p class="font-semibold text-red-700 text-left pt-5">
            <span class="material-icons align-middle">
                close
            </span>
            <span class="pl-2">{{ 'Integration' | translate }}</span>
          </p>

          <a href="/contacts" class="">
            <p class="w-full py-4 bg-blue-600 mt-8 rounded-xl text-white">
              <span class="font-medium">
                  {{ 'Contact' | translate }}
              </span>
              <span class="pl-2 material-icons align-middle text-sm">
                  east
              </span>
            </p>
          </a>
        </div>
      </div>
      <!-- Enterprise Card -->
      <div class="w-96 p-8 bg-white text-center rounded-3xl pl-16 shadow-xl">
        <h1 class="text-black font-semibold text-2xl">{{ 'Individual' | translate }}</h1>
        <p class="pt-2 tracking-wide">
          <span class="text-gray-400 align-top">$ </span>
          <span class="text-3xl font-semibold">200</span>
          <span class="text-gray-400 font-medium">/ {{ 'month' | translate }}</span>
        </p>
        <hr class="mt-4 border-1">
        <div class="pt-8">
          <p class="font-semibold text-green-700 text-left">
            <span class="material-icons align-middle">
                done
            </span>
            <span class="pl-2">{{ 'Ten places' | translate }}</span>
          </p>
          <p class="font-semibold text-green-700 text-left pt-5">
            <span class="material-icons align-middle">
                done
            </span>
            <span class="pl-2">{{ 'Ten company' | translate }}</span>
          </p>
          <p class="font-semibold text-green-700 text-left pt-5">
            <span class="material-icons align-middle">
                done
            </span>
            <span class="pl-2">{{ 'Integration' | translate }}</span>
          </p>

          <a href="/contacts" class="">
            <p class="w-full py-4 bg-blue-600 mt-8 rounded-xl text-white">
              <span class="font-medium">
                  {{ 'Contact' | translate }}
              </span>
              <span class="pl-2 material-icons align-middle text-sm">
                  east
              </span>
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

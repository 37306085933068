import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
  public searchHandler: any;

  private apiDataService = new DataService();
  public companies: any = [];

  constructor(private http: HttpClient) {
  }

  public ngOnInit(): void {
    this.searchHandler = this.searchBoundHandler.bind(this);
    this.http.get(this.apiDataService.getAPIServerURL('/company/list')).subscribe(
      data => {
        this.companies = data;
      }
    );
  }

  private searchBoundHandler(param: any): void {
    this.http.get(this.apiDataService.getAPIServerURL('/company/search/' + param.search)).subscribe(
      data => {
        this.companies = data;
      }
    );
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private visible: any = new BehaviorSubject(false);

  constructor() {
  }

  public watch(): Observable<boolean> {
    return this.visible.asObservable();
  }

  public open() {
    this.visible.next(true);
  }

  public close() {
    this.visible.next(false);
  }

  public setStep(step: string, data: any) {
    sessionStorage.setItem(step, JSON.stringify(data));
  }

  public getStep(step: string) {
    const stepObj = sessionStorage.getItem(step);
    return stepObj ? JSON.parse(stepObj) : {};
  }
}

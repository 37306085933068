import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../services/data.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {ProfileDataService} from '../services/profile-data.service';
import {Errors} from '../classes/errors';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  private apiDataService = new DataService();

  private file: any = '';

  public errors: Errors = new Errors();

  public success: any = {
    message: ''
  };

  public user: any = {
    profile: {
      avatar: '',
      name: '',
      email: '',
    }
  };

  public profileForm = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    phone: new UntypedFormControl('')
  });

  constructor(private http: HttpClient, private authService: AuthService, private profile: ProfileDataService) {
  }

  public ngOnInit(): void {
    this.http.get(this.apiDataService.getAPIServerURL('/profile')).subscribe(
      data => {
        this.user = data;
        if (this.user.profile instanceof Object) {
          this.profileForm.patchValue({
            name: this.user.profile.name,
            email: this.user.profile.email,
            phone: this.user.profile.phone,
          });
        }
      }
    );
  }

  public save() {
    const profileData = this.profileForm.value;

    profileData.avatar = this.file;

    this.profile.saveProfileData(profileData).subscribe(
      result => {
        if (result.body) {
          if (result.body.status == 'success') {
            this.success.message = result.body.msg;
          }
        }
      },
      this.onError.bind(this)
    );
  }

  public onError(response: any) {
    if (response.error) {
      this.errors.record(response.error.errors);
      this.success.message = '';
    }
  }

  public onFileSelected(event: any): void {
    this.file = event.target.files[0];
  }

  public logout(): void {
    this.authService.logout();
  }

}

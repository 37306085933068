<nav class="flex flex-col bg-gray-100 w-full px-7 pt-4 pb-6 mb-10 rounded-md shadow-sm">
  <div class="flex flex-row border-b items-center justify-between pb-2">
    <span class="text-lg font-semibold dark:text-gray-300">
        Кабинет клиента
    </span>

    <span class="relative">
      <a href="#" class="hover:text-green-500 dark-hover:text-green-300 text-gray-600 dark:text-gray-300">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round">
            <path
              d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
            <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
        </svg>
      </a>
      <div class="absolute w-2 h-2 rounded-full bg-green-500 dark-hover:bg-green-300 right-0 mb-5 bottom-0"></div>
    </span>
  </div>

  <div class="mt-8">
    <div class="flex flex-row items-center relative p-2 rounded">
      <div *ngIf="user.profile.avatar" class="relative flex-shrink-0">
        <span class="absolute right-0 top-0 border-2 border-white mt-px mr-px flex items-center justify-center h-4 w-4 rounded-full bg-green-500"></span>
        <a href="#" class="flex rounded-full w-16 h-16">
          <img src="{{ user.profile.avatar }}" alt class="w-full h-full rounded-full"/>
        </a>
      </div>
      <span *ngIf="!user.profile.avatar" class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
        <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
        <path
          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
        </svg>
      </span>
      <div class="flex flex-col ml-4">
        <h3 class="font-bold uppercase">{{ user.profile.name }}</h3>
        <p class="text-sm text-gray-600">{{ user.profile.email }}</p>
      </div>
    </div>
  </div>

  <ul class="mt-2 text-gray-600">
    <li class="mt-8">
      <a href="/profile" class="flex ">
        <svg class="fill-current h-5 w-5 dark:text-gray-300" viewBox="0 0 24 24">
          <path d="M16 20h4v-4h-4m0-2h4v-4h-4m-6-2h4V4h-4m6 4h4V4h-4m-6 10h4v-4h-4m-6 4h4v-4H4m0 10h4v-4H4m6 4h4v-4h-4M4 8h4V4H4v4z"></path>
        </svg>
        <span class="ml-2 font-medium text-blackdark:text-gray-300">
          Кабинет клиента
        </span>
      </a>
    </li>

    <li class="mt-8">
      <a href="profile/company/create" class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
        </svg>
        <span class="ml-2 font-medium text-black dark:text-gray-300">Компании</span>
      </a>
    </li>

    <li class="mt-8">
      <a href="/profile/stand/list" class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"></path>
        </svg>
        <span class="ml-2 font-medium text-black dark:text-gray-300">Стенды</span>
      </a>
    </li>

    <li class="mt-8">
      <a href="/profile/expo/list" class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"></path>
        </svg>
        <span class="ml-2 font-medium text-black dark:text-gray-300">Выставки</span>
      </a>
    </li>

    <li class="mt-8">
      <a href="/profile/order/list" class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"/>
        </svg>
        <span class="ml-2 font-medium text-black dark:text-gray-300">Заказы</span>
      </a>
    </li>
  </ul>

  <div class="mt-auto flex items-center text-red-700 dark:text-red-400">
    <a (click)="logout()" href="#" class="flex items-center mt-10">
      <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
        <path d="M16 17v-3H9v-4h7V7l5 5-5 5M14 2a2 2 0 012 2v2h-2V4H5v16h9v-2h2v2a2 2 0 01-2 2H5a2 2 0 01-2-2V4a2 2 0 012-2h9z"></path>
      </svg>
      <span class="ml-2 font-medium">Выйти из кабинета</span>
    </a>
  </div>
</nav>

<main class="mx-auto w-full">
  <div class="sm:text-center lg:text-left">
    <app-banner [search]="searchHandler" [image]="'assets/images/b10.jpg'" [page]="'Expo'"
                [text]="'List of available expo'" [slogan]="''"></app-banner>
    <div class="max-w-7xl m-auto">
      <div class="md:grid md:grid-cols-3 md:gap-4 mt-8">
<!--        <app-standard-preview *ngFor="let category of categories" name="{{ category.name }}" preview="{{ category.preview }}" path="{{ '/expo/' + category.link }}"></app-standard-preview>-->
        <app-small-preview *ngFor="let category of categories" name="{{ category.name }}" preview="{{ category.preview }}" path="{{ '/expo/' + category.link }}"></app-small-preview>
      </div>
    </div>
    <div class="mt-5 mb-10 max-w-7xl m-auto">
      <div class="grid grid-cols-1 gap-4 md:grid lg:grid-cols-2 mt-8">
        <app-expo-item *ngFor="let expo of expolist" [expo]="expo"></app-expo-item>
      </div>
    </div>
  </div>
</main>

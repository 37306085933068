import {Component, OnInit, Input, OnChanges} from '@angular/core';
import {PlaceSidebarService} from 'src/app/services/sidebar/place-sidebar.service';

@Component({
  selector: 'app-places-product',
  templateUrl: './places-product.component.html',
  styleUrls: ['./places-product.component.scss']
})
export class PlacesProductComponent implements OnInit, OnChanges {


  @Input() place: any = {
    name: '',
    description: '',
    userData: {
      name: '',
      description: '',
      preview: '',
      price: ''
    }
  };

  @Input() expo: any = {};

  public isVisible: any;

  public products: any = [];

  constructor(private placeSidebarService: PlaceSidebarService) {
  }

  public ngOnInit(): void {
    this.isVisible = this.placeSidebarService.watch();
  }

  public ngOnChanges(changes: any = 'place'): void {
    this.parseProducts(this.place);
  }

  private parseProducts(place: any) {
    if (place.object) {
      let products = place.object.parent.children;
      if (products instanceof Array) {
        this.products = [];
        this.searchProductsRecursive(products);
      }
    }
  }

  private searchProductsRecursive(collection: any) {
    collection.map((children: any) => {
      if (children.children.length != 0) {
        this.searchProductsRecursive(children.children);
      }

      if (children.name.indexOf('object_product') >= 0 || children.name.indexOf('object_estate') >= 0) {
        this.products.push(children);
      }
    });
  }

  public onClose(): void {
    this.placeSidebarService.close();
  }

}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ModalService {

  private visible: any = new BehaviorSubject(false);
  private title: any = new BehaviorSubject('');
  private msg: any = new BehaviorSubject('');
  private type: any = new BehaviorSubject('');

  constructor() {
  }

  public watch(): Observable<boolean> {
    return this.visible.asObservable();
  }

  public getTitle(): Observable<string> {
    return this.title.asObservable();
  }

  public getMsg(): Observable<string> {
    return this.msg.asObservable();
  }

  public getType(): Observable<string> {
    return this.type.asObservable();
  }

  public open(title: string, msg: string, type: string) {
    this.title.next(title);
    this.msg.next(msg);
    this.type.next(type);
    this.visible.next(true);
  }

  public close() {
    this.visible.next(false);
  }
}

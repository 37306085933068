import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataService} from './data.service';
import {FileUploadsService} from './file-uploads.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ProfileDataService {

  private formData: FormData = new FormData();
  private apiDataService = new DataService();

  constructor(private http: HttpClient, private upload: FileUploadsService) {
  }

  public getProfileData(): Observable<any> {
    return this.http.get(this.apiDataService.getAPIServerURL('/profile'), {responseType: 'json'});
  }

  public saveProfileData(formData: any): Observable<any> {

    this.formData.append('name', formData.name);
    this.formData.append('email', formData.email);
    this.formData.append('phone', formData.phone);

    if (formData.avatar) {
      this.formData.append('avatar', formData.avatar);
    }

    return this.upload.upload(this.formData, this.apiDataService.getAPIServerURL('/profile/save'));
  }

  public saveCompany(formData: any, action: string, id: any = ''): Observable<any> {

    this.formData.append('name', formData.name);
    this.formData.append('email', formData.email);
    this.formData.append('phone', formData.phone);
    this.formData.append('website', formData.website);
    this.formData.append('address', formData.address);
    this.formData.append('description', formData.description);
    this.formData.append('content', formData.content);

    if (formData.logo) {
      this.formData.append('logo', formData.logo);
    }

    if (formData.preview) {
      this.formData.append('preview', formData.preview);
    }
    return this.upload.upload(this.formData, this.apiDataService.getAPIServerURL('/profile/' + action + '/company/' + id));
  }

  public savePlace(formData: any, place: string): Observable<any> {
    return this.http.post(this.apiDataService.getAPIServerURL('/profile/place/' + place), formData, httpOptions);
  }

  public saveExpo(formData: any, expo: string): Observable<any> {
    // return this.http.post(this.apiDataService.getAPIServerURL('/profile/expo/' + expo), formData, httpOptions);
    return this.upload.upload(formData, this.apiDataService.getAPIServerURL('/profile/expo/' + expo));
  }

  public saveProduct(formData: any, place: string, product: string): Observable<any> {
    return this.upload.upload(formData, this.apiDataService.getAPIServerURL('/profile/place/' + place + '/product/' + product));
  }

  public deleteCompany(company: any): Observable<any> {
    return this.http.delete(this.apiDataService.getAPIServerURL('/profile/company/' + company + '/delete'));
  }

  public deletePlace(place: any): Observable<any> {
    return this.http.delete(this.apiDataService.getAPIServerURL('/profile/place/' + place.id + '/delete'));
  }
}

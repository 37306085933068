import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../../services/data.service';
import {ActivatedRoute} from '@angular/router';
import {Errors} from 'src/app/classes/errors';
import {FileUploadsService} from 'src/app/services/file-uploads.service';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

@Component({
  selector: 'app-stand-edit',
  templateUrl: './stand-edit.component.html',
  styleUrls: ['./stand-edit.component.scss']
})
export class StandEditComponent implements OnInit {

  public apiDataService = new DataService();

  public errors: Errors = new Errors();

  public success: any = {
    message: ''
  };

  public standData = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
    description: new UntypedFormControl('')
  });

  private id: any = '';
  public place: any = {};
  public products: any = [];

  private preview?: Blob;
  private formData: FormData = new FormData();

  constructor(private http: HttpClient, private route: ActivatedRoute, private upload: FileUploadsService) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  public ngOnInit(): void {

    this.http.get(this.apiDataService.getAPIServerURL('/places/' + this.id)).subscribe(
      data => {
        this.place = data;
        this.standData.patchValue({
          name: this.place.name,
          status: this.place.status,
          description: this.place.description,
        });

        if (this.place.model) {
          this.parseProducts(this.place.model.url);
        }
      }
    );
  }

  public onSubmit(data: any): void {

    // data.expo_id = this.place.expo_id;
    // data.company_id = this.place.company_id;
    // data.position = this.place.position;

    // this.profile.savePlace(data, this.place.id).subscribe(
    //     data => {
    // 		if(data.status == 'success') {
    // 			this.success.message = data.msg;
    // 		}
    // 	},
    // 	this.onError.bind(this)
    // );

    this.place.preview = this.preview;
    this.formData.append('name', data.name);

    if (this.preview) {
      this.formData.append('preview', this.place.preview);
    }

    this.formData.append('description', data.description);
    this.formData.append('position', this.place.position);

    this.formData.append('expo_id', this.place.expo_id);
    this.formData.append('company_id', this.place.company_id);

    this.upload.upload(this.formData, this.apiDataService.getAPIServerURL('/profile/place/' + this.place.id)).subscribe(
      (response: any) => {
        const data = response.body;
        if (data) {
          if (data.status === 'success') {
            this.success.message = data.msg;
          }
        }
      },
      this.onError.bind(this)
    );
  }

  public onError(response: any) {
    if (response.error) {
      this.errors.record(response.error.errors);
      this.success.message = '';
    }
  }

  public onPreviewSelected(event: any) {
    this.preview = event.target.files[0];
  }

  private parseProducts(model: string) {
    let dracoLoader = new DRACOLoader();

    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');
    dracoLoader.setDecoderConfig({type: 'js'});

    let loader = new GLTFLoader();
    loader.setDRACOLoader(dracoLoader);
    loader.setCrossOrigin('');

    loader.loadAsync(model, (progressEvent: ProgressEvent) => {
      console.log(Math.round((progressEvent.loaded / progressEvent.total) * 100));
    }).then((gltf) => {
      if (gltf.scene.children) {
        this.searchProductsRecursive(gltf.scene.children);
      }
    });
  }

  private searchProductsRecursive(collection: any) {
    collection.map((children: any) => {
      if (children.children.length != 0) {
        this.searchProductsRecursive(children.children);
      }

      if (children.name.indexOf('object_product') >= 0 || children.name.indexOf('object_estate') >= 0) {
        this.products.push(children);
      }
    });
  }
}

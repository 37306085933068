<main class="mx-auto w-full px-4 sm:mt-6 sm:px-6 md:mt-6 lg:mt-6 lg:px-8 xl:mt-6 mb-10">
  <div>
    <div class="md:grid md:grid-cols-4 md:gap-6">
      <!-- LEFT PROFILE BAR -->
      <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Стенды</h3>
        <p class="mt-1 text-sm text-gray-600">В этом разделе вы можете посмотреть все ваши стенды и отредактировать их.</p>
        <div class="mt-10 flex">
          <a href="/profile/stand/list"
             class="border border-teal-500 text-teal-500 block rounded-sm font-bold py-4 px-6 mr-2 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2 fill-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"/>
            </svg>
            <span class="ml-3">Вернуться назад</span>
          </a>
        </div>
        <div class="flex mt-10">
          <div class="w-full">
            <h3 class="font-bold text-gray-600 text-left">Список продукции</h3>
            <div class="mt-5 w-full">
              <div *ngFor="let product of products" class="w-full border-t-2 border-gray-100 font-medium text-gray-600 py-4 px-4 w-full block hover:bg-gray-100 transition duration-150">
                <span *ngIf="product.userData">
                  {{ product.userData.name }}
                </span>
                <a href="/profile/stand/{{ place.id }}/product/{{ product.name }}" class="text-green-400 float-right"
                   title="Редактировать">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5 md:mt-0 md:col-span-3">
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <form action="#" method="POST" [formGroup]="standData" (ngSubmit)="onSubmit(standData.value)" enctype="multipart/form-data">
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div>
                    <label for="name" class="block text-sm font-medium text-gray-700">Название стенда <span
                      class="text-sm text-red-500">*</span></label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                      <input type="text" name="name" id="name" formControlName="name" class="py-3 pl-5 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="Название">
                    </div>
                    <app-error *ngIf="errors.get('name')" [error]="errors.get('name')"></app-error>
                  </div>
                  <div>
                    <label for="status" class="block text-sm font-medium text-gray-700">Статус</label>
                    <div class="mt-1 flex">
                      {{ place.status }}
                      <input type="hidden" name="status" id="status" formControlName="status" class="py-3 pl-5 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" readonly placeholder="Статус">
                    </div>
                  </div>
                  <div>
                    <label class="block text-sm font-medium text-gray-700">Обложка компании</label>
                    <div *ngIf="place.preview" class="w-52">
                      <img src="{{place.preview}}" alt="{{ place.name }}" class="w-full">
                    </div>
                    <div class="mt-1 flex items-center">
                      <label for="file_upload_preview" class="mt-5 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span>Загрузить Обложку</span>
                        <input (change)="onPreviewSelected($event)" id="file_upload_preview" name="preview" type="file" class="sr-only">
                      </label>
                    </div>
                    <app-error *ngIf="errors.get('preview')" [error]="errors.get('preview')"></app-error>
                  </div>
                  <div>
                    <label for="description" class="block text-sm font-medium text-gray-700">Описание <span class="text-sm text-red-500">*</span>
                    </label>
                    <div class="mt-1">
                      <textarea id="description" name="description" formControlName="description" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md pl-5 h-75" placeholder="Описание"></textarea>
                    </div>
                    <app-error *ngIf="errors.get('description')" [error]="errors.get('description')"></app-error>
                    <p class="mt-2 text-sm text-gray-500">
                      Небольшой текст с информацией о стенде
                    </p>
                  </div>
                  <div *ngIf="success.message" class="flex items-center bg-green-500 border-l-4 border-green-700 py-2 px-3 shadow-md mb-2">
                    <div class="text-white max-w-xs ">
                      {{ success.message }}
                    </div>
                  </div>
                </div>
                <input type="hidden" name="expo_id" id="expo_id" value="{{ place.expo_id }}">
                <input type="hidden" name="company_id" id="company_id" value="{{ place.company_id }}">
                <input type="hidden" name="position" id="position" value="{{ place.position }}">
                <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Сохранить
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<main class="mx-auto w-full">
  <div class="sm:text-center lg:text-left mb-10">
    <app-banner [search]="searchHandler" [image]="'assets/images/b11.jpg'" [page]="'Companies ' | translate"
                [text]="'Companies list' | translate" [slogan]="'More information about companies' | translate"></app-banner>
    <div class="max-w-7xl m-auto px-5">
      <div class="md:grid md:grid-cols-2 md:gap-4 mt-8">
        <app-company-item *ngFor="let company of companies" [company]="company"></app-company-item>
      </div>
    </div>
  </div>
</main>

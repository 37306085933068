<nav class="bg-black">
  <div class="max-w-7xl mx-auto px-4">
    <div class="relative flex items-center justify-between h-16">
      <div class="flex-1 flex items-center sm:items-stretch sm:justify-start">
        <div class="flex-shrink-0 flex items-center">
          <app-logo [color]="'white'"></app-logo>
        </div>
        <div class="ml-3 flex items-center sm:hidden">
          <button
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-expanded="false">
            <span class="sr-only">{{ 'Expo menu' | translate }}</span>
            <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
            <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>

        <div class="hidden sm:block sm:ml-6">
          <div class="flex space-x-4">
            <a routerLink="/"
               class="{{ isCurrentUrl('/') }} text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium uppercase">{{ 'Home' | translate }}</a>
            <a routerLink="/expo"
               class="{{ isCurrentUrl('/expo') }} text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium uppercase">{{ 'Exhibitions' | translate }}</a>
            <a routerLink="/events"
               class="{{ isCurrentUrl('/events') }} text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium uppercase">{{ 'Events' | translate }}</a>
            <a routerLink="/company"
               class="{{ isCurrentUrl('/company') }} text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium uppercase">{{ 'Companies' | translate }}</a>
            <a routerLink="/contacts"
               class="{{ isCurrentUrl('/contacts') }} text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium uppercase">{{ 'Contacts' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="hidden sm:hidden">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <a routerLink="/expo"
         class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium uppercase">{{ 'Exhibitions' | translate }}</a>
      <a routerLink="/companies"
         class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium uppercase">{{ 'Companies' | translate }}</a>
      <a routerLink="/contacts"
         class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium uppercase">{{ 'Contacts' | translate }}</a>
    </div>
  </div>
</nav>

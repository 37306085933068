<div class="absolute min-h-screen flex items-center justify-center w-full">
  <div class="w-100">
    <app-logo [color]="'black'"></app-logo>
    <div>
      <div class='bg-gray-300 w-full mt-4' style="height: 4px">
        <div class='bg-gray-500 h-full' [style.width]="loadPercent"></div>
      </div>
    </div>
  </div>
</div>

import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-expo-item',
  templateUrl: './expo-item.component.html',
  styleUrls: ['./expo-item.component.scss']
})
export class ExpoItemComponent {
  @Input() expo: any = [];

  constructor() {}
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';

import {IntroComponent} from './pages/intro/intro.component';
import {ExpoComponent} from './widgets/expo/expo.component';
import {NavigationComponent} from './layouts/partials/navigation/navigation.component';
import {LoginComponent} from './pages/auth/login/login.component';
import {RegisterComponent} from './pages/auth/register/register.component';
import {SidebarComponent} from './widgets/sidebar/sidebar.component';
import {CompanyListComponent} from './pages/company/company-list/company-list.component';
import {CompanyShowComponent} from './pages/company/company-show/company-show.component';
import {ContactComponent} from './pages/contact/contact.component';
import {LayoutComponent} from './layouts/layout/layout.component';
import {CategoryComponent} from './pages/category/category.component';
import {ExpoListComponent} from './pages/expo/expo-list/expo-list.component';
import {BannerComponent} from './banner/banner.component';
import {ProfileComponent} from './profile/profile.component';
import {AuthInterceptor} from './auth.interceptor';
import {NavigationProfileDataComponent} from './layouts/partials/navigation-profile-data/navigation-profile-data.component';
import {CompanySaveComponent} from './profile/company/company-save/company-save.component';
import {ExpoItemComponent} from './pages/expo/expo-item/expo-item.component';
import {PriceComponent} from './pages/prices/price/price.component';
import {ExpoShowComponent} from './pages/expo/expo-show/expo-show.component';
import {CompanyItemComponent} from './pages/company/company-item/company-item.component';
import {FooterComponent} from './layouts/partials/footer/footer.component';
import {LogoComponent} from './layouts/partials/logo/logo.component';
import {CompanyPlacesComponent} from './pages/company/company-places/company-places.component';
import {PreloaderComponent} from './widgets/preloader/preloader.component';
import {SubscribeComponent} from './widgets/subscribe/subscribe.component';
import {StandListComponent} from './profile/stand/stand-list/stand-list.component';
import {PlacesProductComponent} from './widgets/sidebar/places-product/places-product.component';
import {ProductShowComponent} from './pages/product/product-show/product-show.component';
import {StandEditComponent} from './profile/stand/stand-edit/stand-edit.component';
import {ProductEditComponent} from './profile/stand/product-edit/product-edit.component';
import {ModalComponent} from './widgets/modal/modal.component';
import {NavigationSidebarComponent} from './profile/sidebar/navigation-sidebar/navigation-sidebar.component';
import {ProfileOrderListComponent} from './profile/orders/profile-order-list/profile-order-list.component';
import {StriptTagPipe} from './helpers/stript-tag.pipe';
import {ErrorComponent} from './widgets/error/error.component';
import {ExpoViewComponent} from './pages/expo/expo-view/expo-view.component';
import {SafeUrlPipe} from './helpers/safe-url.pipe';
import {ExpoPlacesComponent} from './pages/expo/expo-places/expo-places.component';
import {HomeIndexComponent} from './pages/home/home-index.component';
import {ProfileExpoListComponent} from './profile/expo/profile-expo-list/profile-expo-list.component';
import {ProfileExpoSaveComponent} from './profile/expo/profile-expo-save/profile-expo-save.component';
import {EventsShowComponent} from './pages/events/events-show/events-show.component';
import {EventsListComponent} from './pages/events/events-list/events-list.component';
import {EventsItemComponent} from './pages/events/events-item/events-item.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SmallPreviewComponent } from './widgets/preview/small-preview/small-preview.component';
import { StandardPreviewComponent } from './widgets/preview/standard-preview/standard-preview.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    ExpoComponent,
    NavigationComponent,
    LoginComponent,
    RegisterComponent,
    SidebarComponent,
    CompanyListComponent,
    ExpoListComponent,
    CompanyShowComponent,
    ContactComponent,
    LayoutComponent,
    CategoryComponent,
    BannerComponent,
    ProfileComponent,
    NavigationProfileDataComponent,
    CompanySaveComponent,
    ExpoItemComponent,
    PriceComponent,
    ExpoShowComponent,
    CompanyItemComponent,
    FooterComponent,
    LogoComponent,
    CompanyPlacesComponent,
    PreloaderComponent,
    SubscribeComponent,
    StandListComponent,
    PlacesProductComponent,
    ProductShowComponent,
    StandEditComponent,
    ProductEditComponent,
    ModalComponent,
    NavigationSidebarComponent,
    ProfileOrderListComponent,
    StriptTagPipe,
    ErrorComponent,
    ExpoViewComponent,
    SafeUrlPipe,
    ExpoPlacesComponent,
    HomeIndexComponent,
    ProfileExpoListComponent,
    ProfileExpoSaveComponent,
    EventsShowComponent,
    EventsListComponent,
    EventsItemComponent,
    SmallPreviewComponent,
    StandardPreviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    })

  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_ENUM, API_URL} from '../enums/http.enum';
import {BaseHttpService} from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class EventHttpService extends BaseHttpService{

  constructor(private http: HttpClient) {
    super();
  }

  public get(id: string = ''): Observable<any> {
    return this.httpSender(this.http.get(`${API_URL}/${API_ENUM.EVENT}/${id}`));
  }

  public last(): Observable<any> {
    return this.httpSender(this.http.get(`${API_URL}/${API_ENUM.EVENT}/${API_ENUM.LAST}`));
  }
}

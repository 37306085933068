<div class="w-full rounded-md overflow-hidden bg-cover bg-center my-1"
     [style.background-image]="'url(' + preview  + ')'">
  <div class="bg-gray-900 bg-opacity-50 flex items-center h-full">
    <div class="px-10 max-w-xl py-5">
      <h2 class="text-1xl text-white font-semibold inline-block uppercase">
        <span>{{ name }}</span>
      </h2>
      <a href="{{ path }}"
         class="flex items-center text-white text-sm uppercase font-medium rounded hover:underline focus:outline-none">
        <span>{{ 'More details' | translate }}</span>
        <svg class="h-5 w-5 mx-2" fill="none" stroke-linecap="round" stroke-linejoin="round"
             stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
        </svg>
      </a>
    </div>
  </div>
</div>

<div *ngIf="isVisible | async as visible"
     class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-5000 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
     id="modal_id">
  <div class="absolute bg-black opacity-80 inset-0 z-0"></div>
  <div class="w-full max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white">
    <div class="">
      <button class="w-5 absolute right-5 z-0 text-gray-600" (click)="onCancel()">
        <svg
          class="fill-current text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="22"
          height="22"
        >
          <path
            class="heroicon-ui"
            d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
          />
        </svg>
      </button>
      <div class="text-center p-5 flex-auto justify-center">
        <svg *ngIf="type.source.value === 'delete'" xmlns="http://www.w3.org/2000/svg"
             class="w-16 h-16 flex items-center text-red-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd"/>
        </svg>
        <svg *ngIf="type.source.value === 'error'" xmlns="http://www.w3.org/2000/svg"
             class="w-16 h-16 flex items-center text-red-500 mx-auto" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        <svg *ngIf="type.source.value === 'success'" xmlns="http://www.w3.org/2000/svg"
             class="w-16 h-16 flex items-center text-green-500 mx-auto" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        <h2 class="text-xl font-bold py-4 " *ngIf="title.source">{{ title.source.value }}</h2>
        <p class="text-sm text-gray-500 px-8" *ngIf="msg.source">{{ msg.source.value }}</p>
      </div>
      <div class="p-3  mt-2 text-center space-x-4 md:block" *ngIf="msg.source.value === 'alert'">
        <button (click)="onCancel()"
                class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded hover:shadow-lg hover:bg-gray-100">
          Cancel
        </button>
        <button (click)="onOkey()"
                class="mb-2 md:mb-0 bg-green-500 border border-green-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded hover:shadow-lg hover:bg-green-600">
          Okey!
        </button>
      </div>
    </div>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../../services/data.service';
import {ActivatedRoute} from '@angular/router';
import {ProfileDataService} from 'src/app/services/profile-data.service';
import {Errors} from 'src/app/classes/errors';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {

  public apiDataService = new DataService();

  public errors: Errors = new Errors();

  public products: any = [];

  public productData = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    serial: new UntypedFormControl(''),
    vendor: new UntypedFormControl(''),
    price: new UntypedFormControl(''),
    description: new UntypedFormControl('')
  });

  private id: any = '';
  public originalName: any = '';
  public place: any = {};
  public product: any = {};
  private preview: any = null;
  private spec: any = null;

  public success: any = {
    message: ''
  };

  private formData: any = new FormData();

  constructor(private profile: ProfileDataService, private http: HttpClient, private route: ActivatedRoute) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.originalName = this.route.snapshot.paramMap.get('product');
  }

  public ngOnInit(): void {
    this.http.get(this.apiDataService.getAPIServerURL('/places/' + this.id + '/product/' + this.originalName)).subscribe(
      data => {
        this.product = data;
        this.productData.patchValue({
          name: this.product.name,
          serial: this.product.serial,
          vendor: this.product.vendor,
          price: this.product.price,
          description: this.product.description
        });
      }
    );
  }

  public onPreviewSelected(event: any) {
    this.preview = event.target.files[0];
  }

  public onSpecSelected(event: any) {
    this.spec = event.target.files[0];
  }

  public onSubmit(data: any): void {
    for (let k in data) {
      if (k) {
        this.formData.append(k, data[k]);
      }
    }
    this.formData.append('original_name', this.originalName);

    if (this.preview) {
      this.formData.append('preview', this.preview);
    }

    if (this.spec) {
      this.formData.append('spec', this.spec);
    }

    this.profile.saveProduct(this.formData, this.id, this.originalName).subscribe(
      (response: any) => {
        const data = response.body;
        if (data) {
          if (data.status === 'success') {
            this.success.message = data.msg;
          }
        }
      },
      this.onError.bind(this)
    );
  }

  public onError(response: any) {
    if (response.error) {
      this.errors.record(response.error.errors);
      this.success.message = "";
    }
  }
}

<main class="w-full">
  <div class="lg:px-0">
    <section class="text-gray-700 body-font bg-main mb-10">
      <div class="px-5">
        <div class="grid grid-cols-1 gap-4 md:grid lg:grid-cols-2 mt-8">
          <div class="relative block md:flex items-start mt-6" #rendererContainer></div>
          <div class="w-full lg:py-10 mt-10 lg:mt-0">
            <h1 class="text-gray-900 text-3xl title-font font-medium mb-1">{{ product.name}}</h1>
            <h2 class="text-sm title-font text-gray-500 tracking-widest">{{ place.name }}</h2>
            <h2 class="text-sm title-font text-gray-500 tracking-widest">{{ product.vendor }}</h2>
            <div class="my-5">
              <a href="/expo/online/{{ place.expo_id }}"
                 class="flex items-start text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded w-56">
                <span>Вернуться назад</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-5" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"/>
                </svg>
              </a>
            </div>
            <p class="leading-relaxed">{{ product.description }}</p>
            <div class="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5"></div>
            <div class="flex">
              <span class="title-font font-medium text-2xl text-gray-900 uppercase">{{ product.price }}</span>
              <button (click)="buyProduct()"
                      class="flex ml-auto text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded">
                <span *ngIf="!isBuy">Написать продавцу</span>
                <span *ngIf="isBuy">Закрыть</span>
              </button>
            </div>
            <form *ngIf="isBuy" method="POST" [formGroup]="clientData" (ngSubmit)="onSubmit(clientData.value)"
                  class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
              <div class="-mx-3 md:flex mb-6">
                <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                         for="name">
                    Имя <span class="text-sm text-red-500">*</span>
                  </label>
                  <input
                    class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                    id="name" formControlName="name" type="text" placeholder="Имя">
                  <app-error *ngIf="errors.get('client_name')" [error]="errors.get('client_name')"></app-error>
                </div>
                <div class="md:w-1/2 px-3">
                  <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                         for="last_name">
                    Фамилия <span class="text-sm text-red-500">*</span>
                  </label>
                  <input
                    class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                    id="last_name" formControlName="lastname" type="text" placeholder="Фамилия">
                  <app-error *ngIf="errors.get('client_lastname')" [error]="errors.get('client_lastname')"></app-error>
                </div>
              </div>
              <div class="-mx-3 md:flex mb-6">
                <div class="md:w-full px-3">
                  <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                         for="email">
                    Email <span class="text-sm text-red-500">*</span>
                  </label>
                  <input
                    class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3"
                    id="email" formControlName="email" type="email" placeholder="Email">
                  <p class="text-grey-dark text-xs italic">Введите адрес электронной почты </p>
                  <app-error *ngIf="errors.get('email')" [error]="errors.get('email')"></app-error>
                </div>
              </div>
              <div class="-mx-3 md:flex mb-2">
                <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                         for="tel">
                    Тел.
                  </label>
                  <input
                    class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                    id="tel" formControlName="phone" type="text" placeholder="Tel.">
                </div>
              </div>
              <div class="-mx-3 md:flex mb-6 mt-6">
                <div class="md:w-full px-3">
                  <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                         for="message">
                    Сообщение <span class="text-sm text-red-500">*</span>
                  </label>
                  <textarea
                    class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3"
                    id="message" formControlName="message" placeholder="Сообщение"></textarea>
                  <p class="text-grey-dark text-xs italic">Текст сообщения</p>
                  <app-error *ngIf="errors.get('message')" [error]="errors.get('message')"></app-error>
                </div>
              </div>

              <div class="flex">
                <button type="submit"
                        class="flex ml-auto text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded">
                  Отправить
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>

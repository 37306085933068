<div class="ml-3 relative">
  <div>
    <button (click)="isVisible = !isVisible"
            class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            id="user-menu" aria-haspopup="true">
      <span class="sr-only">{{ 'Client menu' | translate }}</span>
      <img class="h-8 w-8 rounded-full" src="{{ user.avatar }}" alt="">
      <span class="mx-5 mt-1.5 text-gray-200 uppercase hover:text-red-500">{{ user.name }}</span>
    </button>


  </div>
  <div *ngIf="isVisible"
       class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
       role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
    <a routerLink="/profile" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">{{ 'Account' | translate }}</a>
    <a routerLink="#" (click)="logout()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
       role="menuitem">{{ 'Logout' | translate }}</a>
  </div>
</div>

<div class="w-full h-64 rounded-md overflow-hidden bg-cover bg-left mb-5"
     [style.background-image]="'url(' + event.preview  + ')'">
  <div class="bg-gray-900 bg-opacity-80 flex items-center h-full">
    <div class="px-10 max-w-xl">
      <h2 class="text-2xl text-white font-semibold">
        <a href="/events/{{ event.id }}" class="underline">{{ event.title.substring(0, 150) }}</a>
      </h2>
      <a href="/events/{{ event.id }}"
         class="flex items-center mt-4 text-white text-sm uppercase font-medium rounded hover:underline focus:outline-none">
        <span>{{ 'More details' | translate }}</span>
        <svg class="h-5 w-5 mx-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
             viewBox="0 0 24 24" stroke="currentColor">
          <path d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
        </svg>
      </a>
    </div>
  </div>
</div>

import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Errors} from 'src/app/classes/errors';
import {AuthService} from '../../../services/auth.service';
import {TokenStorageService} from '../../../services/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {

  public errors: Errors = new Errors();

  public form: any = {
    username: null,
    password: null
  };

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router) {
  }

  public onSubmit(): void {
    const {email, password} = this.form;

    this.authService.login(email, password).subscribe(
      data => {
        this.tokenStorage.saveToken(data.token);
        this.tokenStorage.saveUser(data.user);

        if (data.status === 'success') {
          this.router.navigate(['/profile']);
        }
      },
      this.onError.bind(this)
    );
  }

  private onError(response: any) {
    if (response.error) {
      this.errors.record(response.error.errors);
    }
  }

}

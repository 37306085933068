<main class="mx-auto w-full">
  <div class="sm:text-center lg:text-left">
    <app-banner [search]="searchHandler" [image]="'assets/images/231.jpg'" [page]="'Events' | translate" [text]="''"
                [slogan]="'World events' | translate"></app-banner>
    <div class="max-w-7xl m-auto">
      <div class="md:grid md:grid-cols-3 md:gap-4 mt-8">
        <app-events-item *ngFor="let event of events" [event]="event"></app-events-item>
      </div>
    </div>
  </div>
</main>

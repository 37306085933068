import {Component} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {TokenStorageService} from '../../../services/token-storage.service';

@Component({
  selector: 'app-navigation-profile-data',
  templateUrl: './navigation-profile-data.component.html',
  styleUrls: ['./navigation-profile-data.component.scss']
})
export class NavigationProfileDataComponent {

  public isVisible = false;
  public user = {
    name: '',
    email: '',
    avatar: '/assets/images/man.png'
  };

  constructor(private tokenStorage: TokenStorageService, private authService: AuthService) {
    const userData = tokenStorage.getUser();

    this.user.name = userData.name;
    this.user.email = userData.email;
    this.user.avatar = (userData.avatar) ? userData.avatar : this.user.avatar;
  }

  public logout(): void {
    this.authService.logout();
  }

}

import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {DataService} from 'src/app/services/data.service';
import {ProfileDataService} from 'src/app/services/profile-data.service';
import {ActivatedRoute} from '@angular/router';
import {Errors} from 'src/app/classes/errors';

@Component({
  selector: 'app-company-save',
  templateUrl: './company-save.component.html',
  styleUrls: ['./company-save.component.scss']
})
export class CompanySaveComponent implements OnInit {

  public apiDataService = new DataService();

  private id: any = 0;
  private logo: any = '';
  private preview: any = '';

  public errors: Errors = new Errors();

  public success: any = {
    message: ''
  };

  public companyData = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    website: new UntypedFormControl(''),
    address: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    content: new UntypedFormControl(''),
  });

  public companies: any = [];
  public company: any = {};

  constructor(private profile: ProfileDataService, private http: HttpClient, private router: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.id = this.router.snapshot.paramMap.get('id');
    if (this.id) {
      this.http.get(this.apiDataService.getAPIServerURL('/profile/company/' + this.id)).subscribe(
        data => {
          this.company = data;
          if (this.company instanceof Object) {
            this.companyData.patchValue(this.company);
          }
        }
      );
    }


    this.http.get(this.apiDataService.getAPIServerURL('/profile/companies/list')).subscribe(
      data => {
        this.companies = data;
      }
    );
  }

  public onLogoSelected(event: any): void {
    this.logo = event.target.files[0];
  }

  public onPreviewSelected(event: any): void {
    this.preview = event.target.files[0];
  }

  public onSubmit(data: any): void {

    data.logo = this.logo;
    data.preview = this.preview;

    if (this.id) {
      this.profile.saveCompany(data, 'update', this.id).subscribe(
        data => {
          if (data.body) {
            if (data.body.status == 'success') {
              this.success.message = data.body.msg;
            }
          }
        },
        this.onError.bind(this)
      );
    } else {
      this.profile.saveCompany(data, 'create').subscribe(
        data => {
          if (data.body) {
            if (data.body.status == 'success') {
              this.success.message = data.body.msg;
            }
          }
        },
        this.onError.bind(this)
      );
    }
  }

  public onError(response: any) {
    if (response.error) {
      this.errors.record(response.error.errors);
      this.success.message = '';
    }
  }

  public delete(company: any): void {
    if (confirm('Вы действительно хотите удалить компанию?')) {
      this.profile.deleteCompany(company).subscribe(data => {
        this.companies.splice(this.companies.findIndex((i: { id: any; }) => i.id === data.id), 1);
      });
    }
  }

}

import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {SubscribeService} from '../../services/subscribe.service';
import {ModalService} from '../../services/modal/modal.service';
import {Errors} from '../../classes/errors';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent {

  public errors: Errors = new Errors();

  public subscribeData = new UntypedFormGroup({
    email: new UntypedFormControl(''),
  });

  constructor(private subscribe: SubscribeService, private http: HttpClient, private modal: ModalService) {
  }

  public onSubmit(data: any): void {
    this.subscribe.createSubscribe(data).subscribe(
      res => {
        this.modal.open('Subscribe service', res.msg, res.status);

        this.subscribeData.patchValue({
          email: '',
        });
      },
      this.onError.bind(this)
    );

  }

  private onError(response: any) {
    if (response.error) {
      this.errors.record(response.error.errors);
      this.modal.open('Subscribe service', this.errors.get('email'), 'error');
    }
  }

}

import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataService} from 'src/app/services/data.service';

@Component({
  selector: 'app-profile-order-list',
  templateUrl: './profile-order-list.component.html',
  styleUrls: ['./profile-order-list.component.scss']
})
export class ProfileOrderListComponent implements OnInit {

  public user: any = {
    profile: {
      avatar: '',
      name: '',
      email: '',
    }
  };

  public orders: any = [];

  constructor(private http: HttpClient,) {
  }

  private apiDataService = new DataService();

  public ngOnInit(): void {
    this.http.get(this.apiDataService.getAPIServerURL('/profile')).subscribe(
      data => {
        this.user = data;
      }
    );

    this.http.get(this.apiDataService.getAPIServerURL('/profile/order/list')).subscribe(
      data => {
        this.orders = data;
      }
    );
  }
}

import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {TokenStorageService} from './services/token-storage.service';
import {AuthService} from './services/auth.service';
import {HTTP_STATUS} from './enums/errors.enum';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  constructor(private token: TokenStorageService, private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let req = request;
    const token = this.token.getToken();
    if (token != null) {
      req = request.clone({headers: request.headers.set(TOKEN_HEADER_KEY, token)});
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HTTP_STATUS.UNAUTHORIZED) {
          this.authService.redirectIfUnauthorized();
        }
        return throwError(error);
      })
    );
  }
}

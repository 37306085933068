import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../../services/data.service';
import {ProfileDataService} from 'src/app/services/profile-data.service';

@Component({
  selector: 'app-stand-list',
  templateUrl: './stand-list.component.html',
  styleUrls: ['./stand-list.component.scss']
})
export class StandListComponent implements OnInit {

  public apiDataService = new DataService();

  public places: any = [];

  private statusRu: Object = {
    'inproccess': 'В процессе',
    'onvalidation': 'На проверке',
    'verified': 'Верифицировано',
    'unverified': 'Не верифицировано',
    'paid': 'Оплачено',
    'blocked': 'Заблокировано',
  };

  constructor(private profile: ProfileDataService, private http: HttpClient) {
  }

  public ngOnInit(): void {
    this.http.get(this.apiDataService.getAPIServerURL('/profile/places/list')).subscribe(
      data => {
        this.places = data;
      }
    );
  }

  public getStatusStyle(status: string): string {
    if (status === 'inproccess') {
      return 'bg-blue-100 text-blue-800';
    }
    if (status === 'onvalidation') {
      return 'bg-blue-800 text-gray-100';
    } else if (status === 'verified') {
      return 'bg-green-100 text-green-600';
    } else if (status === 'unverified') {
      return 'bg-red-100 text-red-600';
    } else if (status === 'paid') {
      return 'bg-green-100 text-green-800';
    } else if (status === 'expired') {
      return 'bg-red-100 text-red-800';
    } else if (status === 'blocked') {
      return 'bg-red-100 text-red-800';
    } else {
      return 'bg-blue-100 text-blue-800';
    }
  }

  public getStatus(status: string): string {
    return this.statusRu[status];
  }

  public delete(place: any) {
    if (confirm('Вы действительно хотите удалить компанию?')) {
      if (place.status != 'paid') {
        this.profile.deletePlace(place).subscribe(data => {
          this.places.splice(this.places.findIndex((i: { id: any; }) => i.id === data.id), 1);
        });
      }
    }
  }

  public getPaymentUrl(place: any) {
    console.log('payment');
    // var button = $ipsp.get('button');

    // button.setMerchantId(1396424);
    // button.setAmount(15, 'USD', true);
    // button.setResponseUrl(location.href);
    // button.setHost('pay.fondy.eu');
    // button.addField({
    // 	product: place.name,
    // 	product_id: place.id,
    // 	user_id: place.user_id
    // });

    // location.href = button.getUrl();
  }

}

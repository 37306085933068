import {environment} from "../../environments/environment";

export const API_URL = `${environment.api_server}`;

export enum API_ENUM {
  PLACE = 'places',
  EXPO = 'expo',
  EVENT = 'events',
  LAST = 'last',
  PROFILE = 'profile',
  CREATE_PLACE = 'create/place',
  CATEGORY = 'category',
  LOGIN = 'login',
  REGISTER = 'register',
  LOGOUT = 'logout',
}

import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {
  protected formData: FormData = new FormData();
  constructor() { }

  protected httpSender(service: Observable<any>): Observable<any> {
    return service.pipe();
  }

  protected buildParams(params: any) {
    Object.keys(params).map(param => {
      if (params[param] !== undefined && params[param] !== '') {
        this.formData.append(param, params[param]);
      }
    });
  }
}

import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {DataService} from 'src/app/services/data.service';
import {ProfileDataService} from 'src/app/services/profile-data.service';

@Component({
  selector: 'app-profile-expo-list',
  templateUrl: './profile-expo-list.component.html',
  styleUrls: ['./profile-expo-list.component.scss']
})
export class ProfileExpoListComponent implements OnInit {

  public apiDataService = new DataService();

  public expo: any = [];

  private statusRu: Object = {
    'inproccess': 'В процессе',
    'onvalidation': 'На проверке',
    'verified': 'Верифицировано',
    'unverified': 'Не верифицировано',
    'paid': 'Оплачено',
    'blocked': 'Заблокировано',
  };

  constructor(private profile: ProfileDataService, private http: HttpClient) {
  }

  public ngOnInit(): void {
    this.http.get(this.apiDataService.getAPIServerURL('/profile/expo/list')).subscribe(
      data => {
        console.log(data);
        this.expo = data;
      }
    );
  }

  public getStatusStyle(status: string): string {
    if (status === 'inproccess') {
      return 'bg-blue-100 text-blue-800';
    }
    if (status === 'onvalidation') {
      return 'bg-blue-800 text-gray-100';
    } else if (status === 'verified') {
      return 'bg-green-100 text-green-600';
    } else if (status === 'unverified') {
      return 'bg-red-100 text-red-600';
    } else if (status === 'paid') {
      return 'bg-green-100 text-green-800';
    } else if (status === 'expired') {
      return 'bg-red-100 text-red-800';
    } else if (status === 'blocked') {
      return 'bg-red-100 text-red-800';
    } else {
      return 'bg-blue-100 text-blue-800';
    }
  }

  public getStatus(status: string): string {
    return this.statusRu[status];
  }

	getPaymentUrl(expo: any) {
  }

  public delete(expo: any) {
  }
}

import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DataService} from 'src/app/services/data.service';

@Component({
  selector: 'app-expo-view',
  templateUrl: './expo-view.component.html',
  styleUrls: ['./expo-view.component.scss']
})
export class ExpoViewComponent {

  public url: string = '';
  public dataService: DataService = new DataService();

  constructor(private route: ActivatedRoute) {
    const id = this.route.snapshot.paramMap.get('id');
    this.url = this.dataService.getClientURL() + '/expo/embed/' + id;
    console.log(this.url);
  }
}

import {PlaceInterface} from '../interfaces/place.interface';

export class Place implements PlaceInterface {
  public id: string = '';
  public name: string = '';
  public preview: string = '';
  public platform: string = '';
  public platform_size: object = {};
  public position: object = {};
  public description: string = '';
}

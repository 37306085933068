import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Errors} from 'src/app/classes/errors';
import {DataService} from 'src/app/services/data.service';
import {ProfileDataService} from 'src/app/services/profile-data.service';

@Component({
  selector: 'app-profile-expo-save',
  templateUrl: './profile-expo-save.component.html',
  styleUrls: ['./profile-expo-save.component.scss']
})
export class ProfileExpoSaveComponent implements OnInit {

  public apiDataService = new DataService();

  public expo: any = {};
  public categories: any = [];

  private preview: any = '';
  private model: any = '';

  private id: string = '';

  public errors: Errors = new Errors();

  public success: any = {
    message: ''
  };

  public expoData = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    category_id: new UntypedFormControl(''),
    type: new UntypedFormControl(''),
  });

  private formData: any = new FormData();

  constructor(private profile: ProfileDataService, private http: HttpClient, private router: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.http.get(this.apiDataService.getAPIServerURL('/category')).subscribe(
      data => {
        this.categories = data;
      }
    );
  }

  public onPreviewSelected(event: any): void {
    this.preview = event.target.files[0];
  }

  public onModelSelected(event: any): void {
    this.model = event.target.files[0];
  }

  public onSubmit(data: any) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('type', data.type);
    formData.append('category_id', data.category_id);

    if (this.preview) {
      formData.append('preview', this.preview);
    }

    if (this.model) {
      formData.append('model', this.model);
    }

    this.profile.saveExpo(formData, this.id).subscribe(
      data => {
        if (data.body) {
          if (data.body.status == 'success') {
            this.success.message = data.body.msg;
          }
        }
      },
      this.onError.bind(this)
    );
  }

  public onError(response: any) {
    if (response.error) {
      this.errors.record(response.error.errors);
      this.success.message = '';
    }
  }

}

import {Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as THREE from 'three';
import {PointerLockControls} from 'three/examples/jsm/controls/PointerLockControls.js';
import {TransformControls} from 'three/examples/jsm/controls/TransformControls.js';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader.js';
import {FileUploadsService} from '../../services/file-uploads.service';
import {DataService} from '../../services/data.service';
import {TokenStorageService} from '../../services/token-storage.service';
import {HttpClient} from '@angular/common/http';
import {ModalService} from '../../services/modal/modal.service';
import {SidebarService} from '../../services/sidebar/sidebar.service';
import {PlaceSidebarService} from '../../services/sidebar/place-sidebar.service';
import {TWEEN} from 'three/examples/jsm/libs/tween.module.min';
import {OBJECTS_CODES} from '../../enums/objects-codes.enum';

@Component({
  selector: 'app-expo',
  templateUrl: './expo.component.html',
  styleUrls: ['./expo.component.scss']
})

export class ExpoComponent implements OnInit, AfterViewInit {
  @ViewChild('rendererContainer') rendererContainer!: ElementRef;
  @Input() public expo: any = {};

  public id = 0;
  private apiDataService = new DataService();
  public visible = false;
  public isLogined = false;
  public loadPercent: String = '0%';
  public preloaderVisible: Boolean = true;

  public places: any = [];
  public place: any = {
    name: '',
    description: ''
  };

  public width = window.innerWidth;
  public height = window.innerHeight;

  public material: any;

  public helpBox: THREE.Object3D = new THREE.Object3D;

  public renderer = new THREE.WebGLRenderer({antialias: true});

  public scene: THREE.Scene;
  public camera: THREE.PerspectiveCamera;

  public targetPoint: THREE.Mesh = new THREE.Mesh;

  public controls: any;
  public transformControl: any;

  public lockEvent: Boolean = true;
  public isMoved: Boolean = false;
  public previousTouch: any = null;

  public raycaster: THREE.Raycaster;
  public mouse = new THREE.Vector2();

  public objects: Array<any>;

  isShiftDown: Boolean;

  public currentPlacePosition: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
  public currentPlacePlatform: any = {
    name: '',
    size: {}
  };

  public rotation: any;

  public movementX: any;
  public movementY: any;

  public touchX: number = 0;
  public touchY: number = 0;

  public animation: boolean = false;

  public currentClientPosition: any = {
    x: 0,
    z: 0
  };

  public constructor(
    private upload: FileUploadsService,
    private tokenStorage: TokenStorageService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private modalService: ModalService,
    private sidebarService: SidebarService,
    private placeSidebarService: PlaceSidebarService) {

    if (tokenStorage.getToken()) {
      this.isLogined = true;
    }

    this.isShiftDown = false;
    this.objects = [];
    this.id = 0;

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xFFFFFF);
    this.raycaster = new THREE.Raycaster();

    this.renderer.shadowMap.enabled = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.camera = new THREE.PerspectiveCamera(45, this.width / this.height, 1, 1000);

    this.camera.position.set(0, 0, 0);

    this.raycaster = new THREE.Raycaster();

    this.scene.add(this.camera);

    const target = new THREE.RingGeometry(5, 7, 30);

    const targetMaterial = new THREE.MeshBasicMaterial({color: 0x000000});

    this.targetPoint = new THREE.Mesh(target, targetMaterial);
    this.targetPoint.rotation.x = THREE.MathUtils.degToRad(-90);
    this.scene.add(this.targetPoint);

    // this.showTextureMap();

    this.render();
  }

  public ngOnInit(): void {
    debugger
    let id = this.route.snapshot.paramMap.get('id');
    this.http.get(this.apiDataService.getAPIServerURL('/expo/' + id)).subscribe(
      data => {
        this.expo = data;
        if (this.expo.model) {
          this.showPlace({
            model: this.expo.model.url,
            position: {
              x: 0,
              y: 0,
              z: 0
            }
          }, this.expo.places, 'expo');
        }
      }
    );
  }

  public ngAfterViewInit() {
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);

    this.controls = new PointerLockControls(this.camera, this.renderer.domElement);
    this.controls.enableZoom = false;

    this.rendererContainer.nativeElement.addEventListener('mousemove', this.selectTarget.bind(this), false);

    this.rendererContainer.nativeElement.addEventListener('touchmove', this.touchMove.bind(this), false);
    this.rendererContainer.nativeElement.addEventListener('touchstart', this.onMouseDown.bind(this), false);
    this.rendererContainer.nativeElement.addEventListener('touchend', this.onMouseUp.bind(this), false);

    this.rendererContainer.nativeElement.addEventListener('mousemove', this.onMouseMove.bind(this), false);
    this.rendererContainer.nativeElement.addEventListener('mouseup', this.onMouseUp.bind(this), false);
    this.rendererContainer.nativeElement.addEventListener('mousedown', this.onMouseDown.bind(this), false);

    this.rendererContainer.nativeElement.addEventListener('mouseup', this.onClickTarget.bind(this), false);
    this.render();
  }

  private getPlacesObject(object: any): any {
    if (object.name.indexOf('object') >= 0) {
      return object;
    } else if (object.parent.name.indexOf('object') >= 0) {
      return object.parent;
    }
  }

  private selectTarget(event: MouseEvent) {
    event.preventDefault();

    this.mouse.set((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);

    this.raycaster.setFromCamera(this.mouse, this.camera);

    const intersects = this.raycaster.intersectObjects(this.objects, true);

    if (intersects.length > 0) {

      this.scene.remove(this.helpBox);

      const obj = this.getPlacesObject(intersects[0].object);

      if (obj) {
        if (obj.name.indexOf(OBJECTS_CODES.STAND) >= 0) {
          this.helpBox = new THREE.BoxHelper(obj, 0x03c6fc);

          this.scene.add(this.helpBox);
        }

        if (obj.name.indexOf(OBJECTS_CODES.PRODUCT) >= 0 || obj.name.indexOf(OBJECTS_CODES.ESTATE) >= 0) {
          this.helpBox = new THREE.BoxHelper(obj, 0x033441);

          this.scene.add(this.helpBox);
        }

        if (obj.name.indexOf(OBJECTS_CODES.ROAD) >= 0) {
          // show help for navigation
        }
      }
    }
    this.render();
  }

  public onClickTarget(event: MouseEvent) {
    if (this.isMoved) {
      return;
    }

    const mouse = new THREE.Vector2(), raycaster = new THREE.Raycaster();

    mouse.x = (event.offsetX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.offsetY / 800) * 2 + 1;

    raycaster.setFromCamera(mouse, this.camera);

    const intersections = raycaster.intersectObjects(this.objects, true);

    if (intersections.length > 0) {
      const intersect = intersections[0];

      let obj = this.getPlacesObject(intersections[0].object);
      if (obj) {
        if (obj.name.indexOf(OBJECTS_CODES.ART) >= 0 || obj.name.indexOf(OBJECTS_CODES.PLATFORM) >= 0) {
          const box: any = new THREE.Box3();
          box.setFromObject(obj);
          const vector = obj.position;
          this.currentPlacePosition = vector.addVectors( box.min, box.max ).multiplyScalar( 0.5 );
          this.currentPlacePlatform.name = obj.name;
          this.currentPlacePlatform.size = {
            min: box.min,
            max: box.max,
            size: vector.subVectors( box.max, box.min ),
            center: vector.addVectors( box.min, box.max ).multiplyScalar( 0.5 )
          };
          this.sidebarService.open();
        }


        if (obj.name.indexOf(OBJECTS_CODES.ROAD) >= 0) {
          this.animation = true;
          this.moveToPosition(this.controls.getObject().position, intersect.point);
        }

        if (obj.name.indexOf(OBJECTS_CODES.STAND) >= 0 || obj.name.indexOf(OBJECTS_CODES.ESTATE) >= 0) {
          this.place = this.getCurrentPlaceByUUID(obj.uuid);
          this.placeSidebarService.open();
        }

        if (obj.name.indexOf(OBJECTS_CODES.PRODUCT) >= 0) {
          if (obj.parent.name.indexOf(OBJECTS_CODES.STAND) >= 0) {
            this.place = this.getCurrentPlaceByUUID(obj.parent.uuid);
            this.placeSidebarService.open();
          }
        }
      }

    }

    this.render();
  }

  private getCurrentClientPosition() {
    let position = localStorage.getItem('position');

    if (position) {
      this.currentClientPosition = JSON.parse(position);
    }
  }

  private saveCurrentClientPosition(coords: any) {
    localStorage.setItem('position', JSON.stringify(coords));
    localStorage.setItem('expo', this.expo.id);
  }

  private moveToPosition(position: any, coords: any) {
    let self = this;

    animate();

    function animate() {
      console.log('animation');
      if (!self.animation) {
        return;
      }
      requestAnimationFrame(animate);

      TWEEN.update();
      self.render();
    }

    new TWEEN.Tween(position)
      .to({
        x: coords.x,
        z: coords.z
      }, 200)
      .easing(TWEEN.Easing.Cubic.Out)
      .start();

    this.saveCurrentClientPosition({
      x: coords.x,
      z: coords.z
    });
  }

  private onMouseMove(event: any) {
    if (this.lockEvent) {
      return;
    }

    const euler = new THREE.Euler(0, 0, 0, 'YXZ');
    const PI_2 = Math.PI / 2;
    const movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
    const movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;

    euler.setFromQuaternion(this.camera.quaternion);

    euler.y -= movementX * 0.002;
    euler.x -= movementY * 0.002;

    euler.x = Math.max(PI_2 - Math.PI, Math.min(PI_2 / Math.PI / 2, euler.x));

    this.camera.quaternion.setFromEuler(euler);

    this.render();

    this.isMoved = true;
  }

  private touchMove(event: any) {
    event.preventDefault();

    clearInterval(this.rotation);
    if (this.lockEvent) {
      return;
    }

    const touch = event.touches[0];

    const PI_2 = Math.PI / 2;

    if (this.previousTouch) {
      this.movementX = touch.pageX - this.previousTouch.pageX || 0;
      this.movementY = touch.pageY - this.previousTouch.pageY || 0;
    }

    this.movementX = this.movementX ? this.movementX : 0;
    this.movementY = this.movementY ? this.movementY : 0;

    this.touchX = this.touchX - (this.movementY * 0.002);
    this.touchY = this.touchY - (this.movementX * 0.002);

    this.touchX = Math.max(PI_2 - Math.PI, Math.min(PI_2 / Math.PI / 2, this.touchX));
    this.camera.quaternion.setFromEuler(new THREE.Euler(this.touchX, this.touchY, 0, 'YXZ'));

    this.previousTouch = touch;

    this.render();

    this.isMoved = true;
  }

  private onMouseUp() {
    this.lockEvent = true;
    let self = this;

    setTimeout(function() {
      self.isMoved = false;
      self.animation = false;
    }, 500);
  }

  private onMouseDown() {
    this.lockEvent = false;
    this.previousTouch = null;
  }

  private render() {
    // this.renderTexture();

    this.renderer.render(this.scene, this.camera);
  }

  private getCurrentPlaceByUUID(uuid: string): any {
    let currentPlace = {};

    this.places.map((place: any) => {
      if (place.object) {
        if (place.object.uuid == uuid) {
          currentPlace = place;
        }
      }
    });

    return currentPlace;
  }

  public createPlace(place: any) {
    this.showPlace(place, [], 'temporary');
  }

  public controlPlace(mode: any) {
    this.transformControl.setMode(mode);
  }

  public getRotationData(callback: any): any {
    callback(this.rotation, this.currentPlacePosition);
  }

  private showPlace(place: any, places: any, type: string) {

    let self = this;
    let dracoLoader = new DRACOLoader();

    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');
    dracoLoader.setDecoderConfig({type: 'js'});

    let loader = new GLTFLoader();
    loader.setDRACOLoader(dracoLoader);
    loader.setCrossOrigin('');
    loader.loadAsync(place.model, function(progressEvent: ProgressEvent) {

      let progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );

      self.loadPercent = progress + '%';

    }).then((gltf) => {
      this.preloaderVisible = false;

      let position = {
        x: 0,
        y: 0,
        z: 0
      };

      let rotation = {
        _x: 0,
        _y: 0,
        _z: 0
      };

      if (place.position instanceof Object) {
        position = place.position;
      } else {
        position = JSON.parse(place.position);
      }

      if (place.rotation) {
        if (place.rotation instanceof Object) {
          rotation = place.rotation;
        } else {
          rotation = JSON.parse(place.rotation);
        }
      }

      gltf.scene.rotation.set(rotation._x, rotation._y, rotation._z);

      gltf.scene.position.set(position.x, position.y, position.z);

      self.getCurrentClientPosition();

      if (localStorage.getItem('expo') == self.expo.id) {
        self.controls.getObject().position.x = self.currentClientPosition.x;
        self.controls.getObject().position.z = self.currentClientPosition.z;
      }

      self.scene.add(gltf.scene);

      if (places) {
        places.map((place: any) => {
          place.model = place.model.url;
          self.showPlace(place, [], 'place');
        });
      }

      gltf.scene.traverse(obj => {
        if (obj.name.indexOf(OBJECTS_CODES.STAND) >= 0 || obj.name.indexOf(OBJECTS_CODES.ESTATE) >= 0) {
          place.object = obj;
          obj.receiveShadow = true;
        }

        self.objects.push(obj);
      });

      if (type === 'place') {
        self.places.push(place);
      }

      if (type === 'temporary') {
        self.places.push(place);

        self.transformControl = new TransformControls(self.camera, self.renderer.domElement);
        self.transformControl.addEventListener('change', function() {
          self.renderer.render(self.scene, self.camera);
          self.rotation = gltf.scene.rotation;

          if (self.expo.type == 'art') {
            self.currentPlacePosition = gltf.scene.position;
          }
        });

        self.transformControl.addEventListener('dragging-changed', function() {

          self.controls.enabled = false;
          self.currentPlacePosition = gltf.scene.position;

        });

        if (self.expo.type != 'art') {
          self.transformControl.setMode('rotate');
        } else {
          self.transformControl.setMode('translate');
        }

        self.transformControl.attach(gltf.scene);


        self.scene.add(self.transformControl);
      }

      self.render();
    });
  }
}

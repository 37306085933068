<main class="mx-auto w-full px-4 sm:mt-6 sm:px-6 md:mt-6 lg:mt-6 lg:px-8 xl:mt-6 mb-10">
  <div>
    <div class="md:grid md:grid-cols-4 md:gap-6">
      <!-- LEFT PROFILE BAR -->
      <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Стенды</h3>
        <p class="mt-1 text-sm text-gray-600">В этом разделе вы можете посмотреть все ваши стенды и
          отредактировать их.</p>
        <div class="mt-10 flex">
          <a href="/profile"
             class="border border-teal-500 text-teal-500 block rounded-sm font-bold py-4 px-6 mr-2 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2 fill-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"/>
            </svg>
            <span class="ml-3">Вернуться назад</span>
          </a>
        </div>
      </div>

      <!-- RIGHT PROFILE BAR -->
      <div class="mt-5 md:mt-0 md:col-span-3">
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      ID
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Название
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Статус
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Компания
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Редактировать</span>
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Удалить</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                  <tr *ngFor="let place of places">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">{{ place.id }}</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">{{ place.name }}</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                         [ngClass]="getStatusStyle(place.status)">
                        {{ getStatus(place.status) }}
                      </p>
                      <div *ngIf="place.status === 'verified'" class="mt-3">
                        <button (click)="getPaymentUrl(place)" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"/>
                          </svg>
                          <span class="ml-3 leading-6">Оплатить</span>
                        </button>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div *ngIf="place.company">{{ place.company.name }}</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a href="/profile/stand/{{ place.id }}" class="text-indigo-600 hover:text-indigo-900">Редактировать</a>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button *ngIf="place.status !== 'paid'" (click)="delete(place)" class="text-red-600 hover:text-red-700">Удалить
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <p class="text-sm text-gray-600 mt-5" *ngIf="places.length">На данный момент у вас нет добавленных стендов</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<main class="max-w-7xl m-auto px-5 my-10">
  <div class="sm:text-center lg:text-left">
    <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl ml-0 mb-5 text-left">
      <span class="block xl:inline">{{ 'Contacts' | translate }}</span>
    </h1>
    <div class="grid grid-cols-1 gap-4 md:grid lg:grid-cols-2 mt-8">
      <div class="w-full">
        <iframe class="rounded-lg w-full"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50356.13886719333!2d34.99881925365076!3d48.44657956654869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dbe303fd08468f:0xa1cf3d5f2c11aba!2z0JTQvdC40L_RgNC-LCDQlNC90LXQv9GA0L7Qv9C10YLRgNC-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwgNDkwMDA!5e0!3m2!1sru!2sua!4v1622644251277!5m2!1sru!2sua"
                style="border:0;" height="450" allowfullscreen="" loading="lazy"></iframe>
      </div>
      <div class="w-full">
        <div class="p-6 mr-2 bg-gray-100 dark:bg-gray-800 sm:rounded-lg w-45">
          <h1 class="text-4xl sm:text-5xl text-gray-800 dark:text-white font-extrabold tracking-tight">
            {{ 'Connect with us' | translate }}
          </h1>
          <p class="text-normal text-lg sm:text-2xl font-medium text-gray-600 dark:text-gray-400 mt-2">
            {{ 'Contact information to reach us' | translate }}
          </p>

          <p class="mt-5">
            {{ 'Do you have suggestions or questions' | translate  }}
          </p>

          <div class="flex items-center mt-8 text-gray-600 dark:text-gray-400">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                 viewBox="0 0 24 24" class="w-8 h-8 text-gray-500">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
            </svg>
            <div class="ml-4 text-md tracking-wide font-semibold w-40">
              UKRAINE, DNIPRO
            </div>
          </div>

          <div class="flex items-center mt-4 text-gray-600 dark:text-gray-400">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                 viewBox="0 0 24 24" class="w-8 h-8 text-gray-500">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
            </svg>
            <div class="ml-4 text-md tracking-wide font-semibold w-40">
              +380636632061
            </div>
          </div>

          <div class="flex items-center mt-4 text-gray-600 dark:text-gray-400">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                 viewBox="0 0 24 24" class="w-8 h-8 text-gray-500">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
            </svg>
            <div class="ml-4 text-md tracking-wide font-semibold w-40">
              <a href="mailto:expoplaces@gmail.com">expoplaces@gmail.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

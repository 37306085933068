import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Errors} from 'src/app/classes/errors';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  public errors: Errors = new Errors();

  public form: any = {
    username: null,
    email: null,
    password: null
  };
  public isSuccessful = false;
  public isSignUpFailed = false;

  constructor(private authService: AuthService, private router: Router) {
  }

  public onSubmit(): void {
    const {username, email, password} = this.form;

    this.authService.register(username, email, password).subscribe(
      data => {
        if (data.status === 'success') {
          this.isSuccessful = true;
          this.isSignUpFailed = false;

          this.router.navigate(['/login']);
        }
      },
      this.onError.bind(this)
    );
  }

  public onError(response: any) {
    if (response.error) {
      this.errors.record(response.error.errors);
    }

    this.isSignUpFailed = true;
  }

}

<div class="max-w-7xl m-auto px-5">
  <main class="my-10" *ngIf="expo">
    <div class="mb-4 md:mb-0 w-full mx-auto relative">
      <div class="px-4 lg:px-0">
        <h2 class="text-4xl font-semibold text-gray-800 leading-tight mb-5">
          {{ expo.name }}
        </h2>
      </div>
      <img *ngIf="expo.image" src="{{ expo.image }}" alt="" class="w-full object-cover lg:rounded" style="height: 28em;"/>
    </div>
    <div class="flex flex-col lg:flex-row lg:space-x-12">
      <div class="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-3/4">
        <p class="pb-6" [innerHTML]="expo.description"></p>
      </div>
      <div class="w-full lg:w-1/3 mt-5 max-w-screen-sm mt-12">
        <div class="p-8 bg-gray-800 shadow-md hover:shodow-lg rounded-2xl">
          <div class="">
            <h3 class="font-medium text-2xl leading-none text-gray-100 my-3">
              <a href="/expo/online/{{ expo.id }}">{{ expo.name }}</a>
            </h3>
            <div class="text-sm text-gray-200 leading-none" *ngIf="expo.category">{{ expo.category.name }}</div>
            <div class="mt-10">
              <div class="font-medium leading-none text-gray-100" *ngIf="expo.places">
                {{ 'Showcase' | translate }}: {{ expo.places.length }}</div>
              <div class="font-medium leading-none text-gray-100 mt-2" *ngIf="expo.companies">
                {{ 'Companies' | translate }}: {{ expo.companies.length }}</div>
            </div>
            <div *ngIf="expo.access === 'public' || expo.user_id === user.id">
              <h3 class="font-medium leading-none text-gray-100 my-3 mt-10">{{ 'Code for developers' | translate }}</h3>
              <div class="p-3 form-input mt-1 block w-full rounded-md bg-gray-600 border-gray-700 text-gray-200">
                {{ code }}
              </div>
            </div>

            <div class="mt-10 flex">
              <a href="/expo/online/{{ expo.id }}" class="w-full bg-green-500 px-5 py-3 text-sm shadow-sm hover:shadow-lg font-medium border-2 border-green-600 text-white text-center rounded-2xl">
                {{ 'Visit expo' | translate }}
              </a>
            </div>
          </div>
        </div>
        <section class="mt-10">
          <h2 class="text-1xl uppercase font-semibold text-gray-800 leading-tight">{{ 'Available stands' | translate }}</h2>
          <div *ngFor="let place of expo.places">
            <app-small-preview name="{{ place.name }}" preview="{{ expo.preview }}" path="{{ '/expo/places/' + place.id }}"></app-small-preview>
          </div>
        </section>
        <section class="mt-10" *ngIf="expo.companies">
          <h2 class="text-1xl uppercase font-semibold text-gray-800 leading-tight">{{ 'Available companies' | translate }}</h2>
          <div *ngFor="let company of expo.companies" class="flex flex-col p-3 my-5 bg-gray-100 rounded-2xl">
            <div class="flex items-center justify-between">
              <a href="/company/{{ company.id }}">
                <span class="inline-block truncate w-40 text-gray-600 uppercase pt-2">
                  <img src="{{ company.logo }}" alt="" class="h-10 inline-block mr-3"> {{ company.name }}</span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</div>

import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {DataService} from 'src/app/services/data.service';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit {
  public searchHandler: any;

  private apiDataService = new DataService();
  public events: any = [];

  constructor(private http: HttpClient) {
  }

  public ngOnInit(): void {
    this.searchHandler = this.searchBoundHandler.bind(this);
    this.http.get(this.apiDataService.getAPIServerURL('/events')).subscribe(
      data => {
        this.events = data;
      }
    );
  }

  private searchBoundHandler(param: any): any {
    this.http.get(this.apiDataService.getAPIServerURL('/events/search/' + param.search)).subscribe(
      data => {
        this.events = data;
      }
    );
  }
}

import {Component, Input} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';

@Component({
  selector: 'app-navigation-sidebar',
  templateUrl: './navigation-sidebar.component.html',
  styleUrls: ['./navigation-sidebar.component.scss']
})
export class NavigationSidebarComponent {

  constructor(private authService: AuthService) {
  }

  @Input() user: any = {
    profile: {
      avatar: '',
      name: '',
      email: '',
    }
  };

  public logout(): void {
    this.authService.logout();
  }

}

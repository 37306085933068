export class Errors {

  private errors: any = {};

  constructor() {
  }

  public get(field: string) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  public record(errors: Object) {
    this.errors = errors;
  }
}

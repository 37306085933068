import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'striptTag'
})
export class StriptTagPipe implements PipeTransform {
  private step: any;

  public transform(value: string): string {
    this.step = value.replace(/<[^>]+>/gm, '');
    return this.step.replace(/&nbsp;/gm, '');
  }

}

<div class="max-w-7xl m-auto px-5">
  <main class="my-10" *ngIf="event">
    <div class="mb-4 md:mb-0 w-full mx-auto relative">
      <div class="px-4 lg:px-0">
        <h2 class="text-4xl font-semibold text-gray-800 leading-tight mb-5">
          {{ event.title }}
        </h2>
      </div>

      <img *ngIf="event.image" src="{{ event.image }}" class="w-full object-cover lg:rounded" style="height: 28em;"/>
    </div>

    <div class="flex flex-col lg:flex-row lg:space-x-12">
      <div class="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-3/4">
        <p class="pb-6" [innerHTML]="event.content"></p>
      </div>

      <div class="w-full lg:w-1/3 mt-5 max-w-screen-sm mt-12">
        <div class="p-8 bg-gray-800 shadow-md hover:shodow-lg rounded-2xl">
          <div>
            <h3 class="font-medium text-2xl leading-none text-gray-100 my-3">{{ event.title }}</h3>
            <div *ngIf="event.category" class="text-sm text-gray-200 leading-none">{{ event.category.name }}</div>
            <div class="mt-5">
              <div class="font-medium leading-none text-gray-100">{{ 'Owner' | translate }}: {{ event.owner }}</div>
              <div class="font-medium leading-none text-gray-100 mt-3">{{ 'Places' | translate }}: {{ event.place }}</div>
              <div *ngIf="event.date_start" class="font-medium leading-none text-gray-100 mt-3">
                {{ 'Start' | translate }}: {{ event.date_start }}</div>
              <div *ngIf="event.date_end" class="font-medium leading-none text-gray-100 mt-3">
                {{ 'End' | translate }}: {{ event.date_end }}</div>
              <div *ngIf="event.website" class="font-medium leading-none text-gray-100 mt-3">
                {{ 'Website' | translate }}: {{ event.website }}</div>
              <div *ngIf="event.status" class="font-medium leading-none text-gray-100 mt-3">
                {{ 'Status' | translate }}: {{ getStatus(event.status) }}</div>
              <div *ngIf="event.price" class="font-medium leading-none text-gray-100 mt-3">{{ 'Price' | translate }}: {{ event.price }}</div>
            </div>
          </div>
        </div>
        <section class="mt-10">
          <h2 class="text-1xl uppercase font-semibold text-gray-800 leading-tight">{{ 'Last added event' | translate }}</h2>
          <div *ngFor="let ev of lastevents">
            <div *ngIf="ev" class="w-full rounded-md overflow-hidden bg-cover bg-center my-5"
                 style="background-image: url('{{ ev.preview }}')">
              <div class="bg-gray-900 bg-opacity-80 flex items-center h-full">
                <div class="px-10 max-w-xl py-5">
                  <h2 class="text-1xl text-white font-semibold inline-block uppercase"><span>{{ ev.title }}</span></h2>
                  <a href="/events/{{ ev.id }}"
                     class="flex items-center text-white text-sm uppercase font-medium rounded hover:underline focus:outline-none">
                    <span>{{ 'Detailed' | translate }}</span>
                    <svg class="h-5 w-5 mx-2" fill="none" stroke-linecap="round"
                         stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                         stroke="currentColor">
                      <path d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</div>

import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../services/data.service';
import {TokenStorageService} from '../../services/token-storage.service';
import {SidebarService} from '../../services/sidebar/sidebar.service';
import {FileUploadsService} from '../../services/file-uploads.service';
import {Errors} from '../../classes/errors';
import {PlaceInterface} from '../../interfaces/place.interface';
import {ProfileHttpService} from '../../https/profile-http.service';
import {Place} from '../../classes/place';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {

  @Input() expo: any = {};
  @Input() position: any = {};
  @Input() platform: any = {
    name: '',
    size: {}
  };

  @Output() createPlace = new EventEmitter<string>();
  @Output() controlPlace = new EventEmitter<string>();
  @Output() rotatePlace = new EventEmitter<Function>();

  private apiDataService = new DataService();

  public isSelected: boolean = false;

  public isVisible: any;
  private file?: Blob;
  private preview?: Blob;
  private formData: FormData = new FormData();

  public step1Form: any = new FormGroup({
    id: new FormControl(''),
    name: new FormControl(''),
    description: new FormControl(''),
    preview: new FormControl(''),
    platform: new FormControl(''),
    platform_size: new FormControl(''),
    position: new FormControl(''),
    company_id: new FormControl(''),
    expo_id: new FormControl(''),
  });

  public errors: Errors = new Errors();
  public success: any = {
    message: ''
  };

  private steps = {
    step1: true,
    step2: false,
    step3: false,
  };

  public user: any = {
    profile: {
      id: null,
      name: null,
      email: null,
      companies: []
    }
  };

  public currentCompany: any = {
    id: '',
    name: '',
    logo: ''
  };

  public place: PlaceInterface = new Place();

  public step1: boolean = true;
  public step2: boolean = false;
  public step3: boolean = false;

  constructor(
    private http: HttpClient,
    private profileService: ProfileHttpService,
    private tokenStorage: TokenStorageService,
    private sidebarService: SidebarService,
    private upload: FileUploadsService) {

  }

  public ngOnInit(): void {
    this.isVisible = this.sidebarService.watch();

    if (this.tokenStorage.getToken()) {
      this.http.get(this.apiDataService.getAPIServerURL('/profile')).subscribe(
        data => {
          this.user = data;
        }
      );
    }
  }

  public selectCompany(company: any) {
    this.currentCompany = company;
    this.isSelected = false;
  }

  public onFileSelected(event: any): void {
    this.file = event.target.files[0];
  }

  public onPreviewSelected(event: any): void {
    this.preview = event.target.files[0];
  }

  public onPrevStep(step: string) {
    Object.keys(this.steps).map(stepItem => {
      this[stepItem] = false;
      if (stepItem === step) {
        this[step] = true;
      }
    });
  }

  public onStep3(): void {
    const self = this;

    this.rotatePlace.emit(function(data: any, positionData: any) {
      self.formData.append('rotation', JSON.stringify(data));
      self.formData.append('position', JSON.stringify(positionData));
      self.formData.append('place_id', self.place.id.toString());
      self.upload.upload(self.formData, self.apiDataService.getAPIServerURL('/profile/rotate/place')).subscribe(
        (response: any) => {
          const data = response.body;
          if (data) {
            if (data.status == 'success') {
              self.success.message = data.msg;
            }
          }
        },
        self.onError.bind(self)
      );
    });
  }

  public onStep2(): void {

    if (this.file) {
      this.place.model = this.file;
      this.formData.append('model', this.place.model);
    }
    this.formData.append('place_id', this.place.id.toString());

    this.upload.upload(this.formData, this.apiDataService.getAPIServerURL('/profile/load/model')).subscribe(
      (response: any) => {
        const data = response.body;
        if (data) {
          if (data.status == 'success') {
            this.createPlace.emit(data.place);
            this.step2 = false;
            this.step3 = true;
          }
        }
      },
      this.onError.bind(this)
    );
  }

  public onStep1(name: string, description: string): void {
    this.place.name = name;
    this.place.description = description;
    this.place.preview = this.preview ? this.preview : '';
    this.place.position = JSON.stringify(this.position);
    this.place.platform = this.platform.name;
    this.place.platform_size = JSON.stringify(this.platform.size);
    this.place.company_id = this.currentCompany.id;
    this.place.expo_id = this.expo.id;
    this.step1Form.setValue(this.place);
    this.profileService.createPlace(this.place).subscribe((response: any) => {
      if (response.status === 'success') {
        this.place = response.place;
        this.step1 = false;
        this.step2 = true;
        this.sidebarService.setStep('step1', this.place);
      }
    }, this.onError.bind(this));
  }

  public rotateModel() {
    this.controlPlace.emit('rotate');
  }

  public translateModel() {
    this.controlPlace.emit('translate');
  }

  public onError(response: any) {
    if (response.error) {
      this.errors.record(response.error.errors);
    }
  }

  public onClose(): void {
    this.sidebarService.close();
  }

}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-small-preview',
  templateUrl: './small-preview.component.html',
  styleUrls: ['./small-preview.component.scss']
})
export class SmallPreviewComponent {
  @Input() public path = '';
  @Input() public name = '';
  @Input() public preview = '';
  constructor() { }

}

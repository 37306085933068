import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-company-show',
  templateUrl: './company-show.component.html',
  styleUrls: ['./company-show.component.scss']
})
export class CompanyShowComponent implements OnInit {
  constructor(private route: ActivatedRoute, private http: HttpClient) {
  }

  private apiDataService = new DataService();
  public company: any = {};
  public places: any = [];

  public ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.http.get(this.apiDataService.getAPIServerURL('/company/' + id)).subscribe(
      data => {
        this.company = data;
      }
    );
    this.http.get(this.apiDataService.getAPIServerURL('/places/company/' + id)).subscribe(
      data => {
        this.places = data;
      }
    );
  }
}

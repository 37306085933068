<div class="flex justify-start my-8">
  <div class="bg-white rounded-lg">
    <div class="flex">
      <form method="POST" [formGroup]="subscribeData" (ngSubmit)="onSubmit(subscribeData.value)">
        <input type="email" required formControlName="email" class="m-1 p-2 appearance-none text-gray-700 text-sm focus:outline-none" placeholder="{{ 'Enter Email' | translate }}">
        <button class="w-auto m-1 p-2 text-sm bg-gray-800 text-gray-100 rounded-lg font-semibold uppercase">{{ 'Subscribe' | translate }}</button>
      </form>
    </div>
  </div>
</div>
<app-modal></app-modal>

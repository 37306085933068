<div *ngIf="isVisible | async as visible"
     class="absolute flex flex-col w-96 right-0 bg-white max-w-md px-6 py-6 m-5 rounded-lg shadow-md">
  <button class="w-5 absolute right-5" (click)="onClose()">
    <svg
      class="fill-current text-gray-600"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="22"
      height="22"
    >
      <path
        class="heroicon-ui"
        d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
      />
    </svg>
  </button>

  <div class="my-4">
    <div class="flex-none">
      <div class="ml-3 justify-evenly py-2">
        <div class="flex flex-wrap ">
          <h2 class="flex-auto text-lg font-medium text-gray-600">{{ place.name }}</h2>
          <div class="w-full flex-none text-xs text-gray-500 font-medium uppercase mt-2">
            {{ expo.name }}
          </div>
        </div>
        <h4 class="text-sm text-gray-700 mt-5">Компания</h4>
        <div class="mt-2" *ngIf="place.company">
          <a href="/company/{{ place.company.id }}" target="_blank">
            <span class="inline-block truncate w-40"><img src="{{ place.company.logo }}" alt=""
                                                          class="h-10 inline-block mr-2"> {{ place.company.name }}</span>
          </a>
        </div>
        <div class="overflow-auto h-96">
          <div *ngIf="place.object || place.preview">
            <div>
              <img *ngIf="place.preview" class="w-full"
                   src="{{ place.preview }}"
                   alt="{{ place.name }}"/>
              <img *ngIf="!place.preview && place.object.userData.preview" class="w-full"
                   src="{{ place.object.userData.preview }}"
                   alt="{{ place.name }}"/>
            </div>
          </div>
          <div *ngIf="place.description">
            <h4 class="text-sm text-gray-700 mt-5">Описание</h4>
            <div class="mb-5">
              {{ place.description.substring(0, 250) | striptTag }}
            </div>
          </div>
          <div class="w-full">
            <div class="w-full">
              <div class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
                   *ngFor="let product of products">
                <div class="w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100"
                     *ngIf="product.userData">
                  <div class="w-full items-center">
                    <div class="mx-2 -mt-1 flex">
                      <img *ngIf="product.userData.preview" src="{{ product.userData.preview }}" alt=""
                           class="w-10 h-10 mr-3">
                      <div>
                        <a target="_parent" href="/expo/product/{{ product.name }}/{{ place.id }}"
                           *ngIf="product.userData.name">{{ product.userData.name }}</a>
                        <div class="text-xs truncate w-full normal-case font-normal -mt-1 text-gray-500"
                             *ngIf="product.userData.description">{{ product.userData.description.substring(0, 30) | striptTag }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div class="mt-10 flex">
      <a href="/expo/places/{{ place.id }}" target="_parent"
         class="w-full bg-blue-500 px-5 py-3 text-sm shadow-sm hover:shadow-lg font-medium border-2 border-blue-600 text-white text-center rounded-2xl">{{ 'Detailed' | translate }}</a>
    </div>
  </div>
</div>

import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-company-places',
  templateUrl: './company-places.component.html',
  styleUrls: ['./company-places.component.scss']
})
export class CompanyPlacesComponent {
  @Input() place: any = {};
  @Input() expo: any = {};

  constructor() {
  }
}

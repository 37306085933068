import {HttpClient} from '@angular/common/http';
import {Component, Input} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})

export class BannerComponent {
  @Input() search: Function = function(param: any) {};
  @Input() image: String = '';
  @Input() page: String = '';
  @Input() text: String = '';
  @Input() slogan: String = '';

  searchData = new UntypedFormGroup({
    search: new UntypedFormControl(''),
  });

  constructor(protected http: HttpClient) {
  }

  public onSubmit(data: any): void {
    this.searchData.patchValue({
      search: ''
    });
    this.search(data);
  }

}

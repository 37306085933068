<div class="max-w-7xl m-auto px-5">
  <main class="my-10">
    <div class="mb-4 md:mb-0 w-full mx-auto relative">
      <div class="px-4 lg:px-0">
        <h2 class="text-4xl font-semibold text-gray-800 leading-tight mb-5">
          {{ company.name }}
        </h2>
        <!-- <a href="{{ company.website }}" class="py-1 text-blue-600 inline-flex items-center justify-center mb-2">
            {{ company.website }}
        </a> -->
      </div>
      <img *ngIf="company.preview" alt="" src="{{ company.preview }}" class="w-full object-cover lg:rounded" style="height: 28em;"/>
    </div>
    <div class="flex flex-col lg:flex-row lg:space-x-12">
      <div class="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-3/4">
        <p class="pb-6" [innerHTML]="company.content"></p>
      </div>
      <div class="w-full lg:w-1/3 mt-5 max-w-screen-sm">
        <div class="w-full bg-white shadow-lg rounded-lg overflow-hidden my-4">
          <div class="py-4 px-6">
            <h1 class="text-2xl font-semibold text-gray-800">
              <img src="{{ company.logo }}" alt="" class="h-15 inline-block mr-2">
              {{ company.name }}
            </h1>
            <p class="py-2 text-lg text-gray-700">{{ company.description }}</p>
            <div class="flex items-center mt-4 text-gray-700">
              <svg class="h-6 w-6 fill-current" viewBox="0 0 512 512">
                <path
                  d="M437.332 80H74.668C51.199 80 32 99.198 32 122.667v266.666C32 412.802 51.199 432 74.668 432h362.664C460.801 432 480 412.802 480 389.333V122.667C480 99.198 460.801 80 437.332 80zM432 170.667L256 288 80 170.667V128l176 117.333L432 128v42.667z"/>
              </svg>
              <h1 class="px-2 text-sm">{{ company.email }}</h1>
            </div>
            <div class="flex items-center mt-4 text-gray-700" *ngIf="company.phone">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"/>
              </svg>
              <h1 class="px-2 text-sm">{{ company.phone }}</h1>
            </div>
            <div class="flex items-center mt-4 text-gray-700">
              <svg class="h-6 w-6 fill-current" viewBox="0 0 512 512">
                <path
                  d="M256 32c-88.004 0-160 70.557-160 156.801C96 306.4 256 480 256 480s160-173.6 160-291.199C416 102.557 344.004 32 256 32zm0 212.801c-31.996 0-57.144-24.645-57.144-56 0-31.357 25.147-56 57.144-56s57.144 24.643 57.144 56c0 31.355-25.148 56-57.144 56z"/>
              </svg>
              <h1 class="px-2 text-sm">{{ company.address }}</h1>
            </div>

          </div>
        </div>
        <div class="py-2 md:rounded mt-10">
          <h2 class="text-1xl uppercase font-semibold text-gray-800 leading-tight">Выставки компании</h2>
          <app-company-places *ngFor="let place of places | slice:0:7" [place]="place"></app-company-places>
        </div>
      </div>
    </div>
  </main>
</div>

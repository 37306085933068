import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  title: any = '';
  msg: any = '';
  type: any = '';

  isVisible: any;

  constructor(private modalService: ModalService) {
  }

  public ngOnInit(): void {
    this.isVisible = this.modalService.watch();
    this.title = this.modalService.getTitle();
    this.msg = this.modalService.getMsg();
    this.type = this.modalService.getType();
  }

  public onOkey() {

  }

  public onCancel() {
    this.modalService.close();
  }

}

import {Component} from '@angular/core';
import {TokenStorageService} from '../../../services/token-storage.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  public isAuth = false;

  constructor(private tokenStorage: TokenStorageService, private router: Router) {
    if (this.tokenStorage.getToken()) {
      this.isAuth = true;
    }
  }

  public isCurrentUrl(url: string): string {
    return (url === this.router.url) ? 'active' : '';
  }

}
